/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react'
import { BG_URL, PUBLIC_URL } from '../../Utils/utils';

export default function ContactUs({ }) {
  return (
    <>
      <div id={"Contact"}>
        <Mobile />
        <Desktop />
      </div>
    </>
  )
}
function Desktop({ }) {
  return (
    <section className='no-mobile' css={css`margin-top:-2px;scroll-snap-align:center;`}>
      <div
        css={css`
        height: 100vh;
        background-image: ${BG_URL(PUBLIC_URL('assets/contact-us/background.png'))};
        background-size: cover;
        background-position: center;
        position: relative;
        display: flex;
        flex-direction: column;
      `}>
        <div css={css`flex-grow:1;`}></div>
        <div className='container'>
          <div css={css`display:flex;flex-direction:row;`}>
            <div css={css`flex-grow:1;`}></div>
            <div css={css`width:700px;height:800px;`}>
              <div css={css`position:relative;width:80%;`}>
                <img src={PUBLIC_URL('assets/contact-us/sign.png')} css={css`width:100%;position:absolute;top:100px;right:0;`} />
                <div css={css`display:flex;align-items:center;align-content:center;flex-direction:column;z-index:2;position:relative;padding-top:150px;`}>
                  <h2 className='text-center' css={css`font-size:56px;font-weight:bolder;color:#441f00;`}>Contact Us</h2>
                  <div css={css`display:flex;flex-direction:row;width:80%;`}>
                    <input className='form-control' placeholder='Email...' css={css`display:inline-block;height:60px;font-size:24px;margin-right:20px;border-radius:7px;`} />
                    <input className='form-control' placeholder='Full Name...' css={css`display:inline-block;height:60px;font-size:24px;border-radius:7px;`} />
                  </div>
                  <div css={css`padding:0px 60px;width:100%;`}>
                    <textarea className='form-control' css={css`height:100px;margin-top:30px;font-size:24px;width:100%;border-radius:7px;`} placeholder='Message...'></textarea>
                  </div>
                  <SubmitButton />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
function Mobile() {
  return (
    <div className='mobile-only' css={css`scroll-snap-align:center;`}>
      <section
        css={css`
        min-height: 100vh;
        background-image: ${BG_URL(PUBLIC_URL('assets/contact-us/background-mobile.png'))};
        background-size: cover;
        background-position: center;
        position: relative;
        display: flex;
        flex-direction: column;
        padding-top:20px;
        @media (max-height:700px ) {min-height: 120vh;}
      `}>
        <div className='container'>
          <div css={css`position:relative;width:100%;`}>
            <img src={PUBLIC_URL('assets/contact-us/sign-mobile.png')} css={css`width:80%;position:absolute;top:150px;left:50%;transform: translateX(-50%);`} />
            <div css={css`display:flex;align-items:center;align-content:center;flex-direction:column;z-index:2;position:relative;padding-top:180px;`}>
              <h2 className='text-center' css={css`font-size:48px;font-weight:bolder;color:#441f00;`}>Contact Us</h2>
              <div css={css`display:flex;flex-direction:row;width:70%;`}>
                <input className='form-control' placeholder='Email...' css={css`display:inline-block;height:40px;font-size:18px;margin-right:20px;border-radius:7px;`} />
                <input className='form-control' placeholder='Full Name...' css={css`display:inline-block;height:40px;font-size:18px;border-radius:7px;`} />
              </div>
              <div css={css`padding:0px 20px;width:70%;`}>
                <textarea className='form-control' css={css`height:80px;margin-top:30px;font-size:24px;width:100%;border-radius:7px;`} placeholder='Message...'></textarea>
              </div>
              <SubmitButton mobileVersion={true} />
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
const NORMAL_BUTTON = PUBLIC_URL('assets/contact-us/button.png');
const ACTIVE_BUTTON = PUBLIC_URL('assets/contact-us/button-pressed.png');
function SubmitButton({ mobileVersion = false }) {

  const [src, setSrc] = useState(NORMAL_BUTTON)
  return (
    <div className='text-center' css={css`margin-top:15px;cursor:pointer;`}>
      <img src={src} onMouseOver={() => { setSrc(ACTIVE_BUTTON) }} onMouseOut={() => setSrc(NORMAL_BUTTON)} width={!mobileVersion ? 'auto' : '200px'} />
    </div>
  )
}
