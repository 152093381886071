import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './Components/App/App';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider } from '@mui/material';
// import { Fullpage, FullpageContext } from '@ap.cx/react-fullpage';

const theme = createTheme({
  direction: 'rtl',
  typography: {
    fontSize: 14,
    fontFamily: [
      'Lato'
    ].join(','),
  },
  status: {
    error: 'red',
    success: 'green',
    warning: 'orange',
  },
  palette: {
    xxl: "36px",
    xl: "32px",
    l: "28px",
    m: "24px",
    s: "20px",
    xs: "16px",
    xxs: "14px",
    title: "23px",
    subTitle: "18px",
    paragraph: "16px",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 'bold'
        }
      }
    },
  }
});


const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(

  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
