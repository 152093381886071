/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Box } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import AboutDelzaar from './AboutDelzaar'
import AboutOurServices from './AboutOurServices'
import AboutOurTeam from './AboutOurTeam'
import ContactUs from './ContactUs'
import Landing from './Landing'
import OurMission from './OurMission'
import OurProjects from './OurProjects'
import Russel from './Russel'
import ReactPageScroller from 'react-page-scroller'
import { useNavigate } from 'react-router-dom'
import AnimateBox from '../../Components/Animations/AnimateBox'
import { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'
import Footer from '../../Components/Layout/Footer';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import Navbar from '../../Components/Layout/Navbar';
import { MissedVideoCallRounded } from '@mui/icons-material';

export default function Home() {
  const navigate = useNavigate()
  const [isAboutUsOpen, setIsAboutUsOpen] = useState(true)
  const LandingRef = useRef();
  const ProjectsRef = useRef();
  const MissionRef = useRef();
  const RusselRef = useRef();
  const ContactUsRef = useRef();
  const headlineRef = useRef();
  const AboutUsRef = useRef();
  const element = document.getElementById('aboutDelzaar')

  function scrollTo(section) {
    section.current.scrollIntoView({ behavior: "smooth" });
  }

  useEffect(() => {
    gsap.fromTo(
      LandingRef.current,
      {
        autoAlpha: 0,
        y: -20,
      },
      {
        y: 0,
        autoAlpha: 1,
        duration: 1,
        ease: "power3.out",
        scrollTrigger: {
          scroller: ".container",
          trigger: headlineRef.current,
          start: "top 60%",
          end: "bottom 0%",
          toggleActions: "play none restart reverse",
        },
      }
    );
    return () => { };
  }, []);
  // useEffect(() => {
  //   let ref = document.getElementById(isAboutUsOpen ? "aboutDelzaar" : "ourMission")
  //   if (ref)
  //     window.scrollTo({
  //       top: ref.offsetTop,
  //       behavior: "smooth",
  //     });
  // }, [isAboutUsOpen])

  // useEffect(() => {
  //   window.addEventListener("scroll", scrollHandler)
  //   return () => window.removeEventListener("scroll", scrollHandler)
  // }, [])

  // const scrollHandler = () => {
  //   let aboutDelzaar = document.getElementById("aboutDelzaar")
  //   let activeSection = window.location.hash.substr(1)
  //   if (!activeSection) {
  //     window.location = (`/#Projects`)
  //     return;
  //   }
  //   if (activeSection == "Home" || activeSection == "home") {
  //     window.location = (`/#Projects`)
  //     return;
  //   }
  //   if (activeSection == "Projects") {
  //     window.location = (`/#Russel`)
  //     return;
  //   }
  //   if (activeSection == "Russel") {
  //     window.location = (`/#About`)
  //     return;
  //   }
  //   if (activeSection == "Ab8out") {
  //     if (aboutDelzaar) window.location = (`/#aboutDelzaar`)
  //     else window.location = (`/#Contact`)
  //     return;
  //   }
  //   if (aboutDelzaar && activeSection == "aboutDelzaar") {
  //     window.location = (`/#Services`)
  //     return;
  //   }
  //   if (activeSection == "Services") {
  //     window.location = (`/#Team`)
  //     return;
  //   }
  //   if (activeSection == "Team") {
  //     window.location = (`/#Contact`)
  //     return;
  //   }
  // }
  // return (
  //   <FullPageSections>
  //     <FullpageSection>
  //       <Landing />
  //     </FullpageSection>

  //     <FullpageSection >
  //       <OurProjects />
  //     </FullpageSection>

  //     <FullpageSection >
  //       <OurMission isAboutUsOpen={isAboutUsOpen} setIsAboutUsOpen={setIsAboutUsOpen} />
  //     </FullpageSection>

  //     <FullpageSection >
  //       <AboutDelzaar />
  //     </FullpageSection>

  //     <FullpageSection >
  //       <AboutOurServices />
  //     </FullpageSection>

  //     <FullpageSection >
  //       <AboutOurTeam />
  //     </FullpageSection>

  //     <FullpageSection >
  //       <ContactUs />
  //     </FullpageSection>

  //   </FullPageSections>
  // )
  return (
    <>
      <Navbar LandingRef={LandingRef} ProjectsRef={ProjectsRef} MissionRef={MissionRef} RusselRef={RusselRef} ContactUsRef={ContactUsRef} scrollTo={scrollTo} />
      <div css={css`
    position:relative;
    overflow-y: auto;
    overscroll-behavior-y: contain;
    scroll-snap-type:y mandatory;
    height:100vh;
    width: 100%;
    -ms-overflow-style:none;
    scrollbar-width: none;
    &::-webkit-scrollbar{
      display:none;
    }`}>
        <div ref={LandingRef}>
          <Landing scrollTo={scrollTo} goToSectionRef={LandingRef} />
        </div>
        <div ref={ProjectsRef}>
          <OurProjects scrollTo={scrollTo} />
        </div>
        <div ref={MissionRef}>
          <OurMission isAboutUsOpen={isAboutUsOpen} setIsAboutUsOpen={setIsAboutUsOpen} scrollTo={scrollTo} AboutUsRef={AboutUsRef} />
        </div>
        <div ref={RusselRef}>
          <Russel scrollTo={scrollTo}  element={element}/>
        </div>
        <div ref={AboutUsRef}>
          <AboutDelzaar scrollTo={scrollTo} />
        </div>
        <div >
          <AboutOurServices />
        </div>
        <div >
          <AboutOurTeam />
        </div>
        <div ref={ContactUsRef}>
          <ContactUs />
        </div>
        <div >
          <Footer />
        </div>
      </div>
    </>
  )
}
