import { PUBLIC_URL } from "../../../Utils/utils";

export const cryingHeart = {
    slug: 'crying-heart',
    thumbnail: PUBLIC_URL('/assets/projects/Crying-heart-thumbnail.jpg'),
    bg: PUBLIC_URL("/assets/projectSingles/backgrounds/crying-heart.png"),
    playIcon: PUBLIC_URL("/assets/projectSingles/play-button.png"),
    video: {
        video: "https://youtu.be/KTgrF_fD1VA",
        typo: PUBLIC_URL("/assets/projectSingles/typographys/crying.png"),
        title: "Short Animation",
        date: "2018",
        particle: undefined

    },
    start: {
        text: ' "Crying Heart" is a short animation about the historical events of the “Battle of Karbala” which was fought on 10 October 680, illustrating the tragic atmosphere of Karbala.',
        image: "/assets/projectSingles/crying-heart/banner2.jpg",
    },
    process: {
        bgParticle: PUBLIC_URL("/assets/projectSingles/sunset/fire-effect-2.png"),
        particles: undefined,
        image: "/assets/projectSingles/crying-heart/banner3.jpg",
        texts: [
            "First we wrote a plot for the animation. We wanted to illustrate the atmosphere of tragic battle of Karbala.",
            "Then we created 3D models based on concepts that were approved, futhermore we pbr textured the models and made them ready to be set in the scene.",
            "We used low poly models with pbr textures and compatibale meshs., how ever it was a personal project yet we tried our best to use our abilities to the maximum."
        ]
    },
    difficulties: {
        bgParticle: undefined,
        texts: [
            "In this project the most challenging part was importing Alembic files which back in the time Unreal Engine had problems with supporting such files and it made flag and cloth simulations exceptionaly hard for out team to make",
            "Later we discovered a way to fix the problem and we were back on track again."
        ],
        image: "/assets/projectSingles/crying-heart/banner4.jpg",
    },
    galleryBgParticle: undefined,
    gallery: [
        "https://youtu.be/9wIKuMeMFvg",
        "https://youtu.be/kqjopVJa6Po",
        "https://youtu.be/Bwn3rirFrL0",
        "https://youtu.be/WsfRkEWZE20",
        "https://youtu.be/jq7has99a7o",
        "https://youtu.be/xV_cHcyDZJ4",
        "https://youtu.be/k_jTbRnJuE4"

    ],
    images: [
        PUBLIC_URL("/assets/projectSingles/crying-heart/03.jpg"),
        PUBLIC_URL("/assets/projectSingles/crying-heart/07.jpg"),
        PUBLIC_URL("/assets/projectSingles/crying-heart/08.jpg"),
        PUBLIC_URL("/assets/projectSingles/crying-heart/09.jpg"),
    ],
};