import { Box, Typography, useTheme } from '@mui/material'
import React from 'react'
import { isEmptyString } from '../../Utils/utils'

export default function TechnicalDifficulties({ content, setFullScreenSrc }) {
  const theme = useTheme()
  return (
    <Box mt={10} sx={{ position: "relative" }}>
      {!isEmptyString(content.bgParticle) ?
        <img style={{
          width: "100%",
          position: "absolute",
          top: "-20%",
          left: 0,
          zIndex: 0,
          height: "100%"
        }} src={content.bgParticle} />
        : undefined
      }
      <Box className="container" sx={{ position: "relative" }}>
        <Typography textAlign={"center"} sx={{ fontSize: { xs: "25px", md: theme.palette.xxl } }}>Technical Difficulties</Typography>
        <Box my={6}>
          {content.texts.map((text) => {
            return <Typography key={"text"} sx={{ fontSize: { xs: "20px", lg: theme.palette.m } }}>{text}</Typography>
          })}
        </Box>
        <div style={{ cursor: "pointer" }} onClick={() => { setFullScreenSrc(content.image) }}>
          <img style={{ width: "100%", aspectRatio: "16/9" }} src={content.image} />
        </div>
      </Box>
    </Box>
  )
}
