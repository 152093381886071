/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import { PUBLIC_URL } from '../../Utils/utils';

export default function Landing({
  scrollTo,
  goToSectionRef
}) {
  return (
    <section id="Home" css={css`
    scroll-snap-align:center;
      position: relative;
      user-select: none !important;
    `}>
      <div css={css`
      height: 100vh;
      position: relative;
      top:0;
      left:0;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    `}>
        <video playsInline={true} autoPlay={true} muted={true} loop={true} poster="polina.jpg" css={css`
       object-fit: cover;
      width: 100%;
      height: 100%;
      position: static;
      top: 0;
      left: 0;
      `}>
          <source src={PUBLIC_URL('videos/home.mp4')} type="video/mp4" />
        </video>
      </div>
      <div css={css`
        position: absolute;
        width:100%;
        height: 100%;
        top:0;
        left:0;
        background-color: #00000045;
      `}></div>
      <div className='text-center no-mobile' css={css`position:absolute;bottom:0;width:100%;color:white;padding-bottom:200px;`}>
        <h1 css={css`font-size:42px;`}>We Believe world would be a better place</h1>
        <h1 css={css`font-size:56px;`}>With more <b>Creative</b> Games</h1>
      </div>
      <div className='mobile-only'>
        <div css={css`color:white;position:absolute;top:0;left:0;width:100%;height:100%;display:flex;align-items:center;align-content:center;`}>
          <div className='text-center' css={css`padding:0px 10px;`}>
            <h1 css={css`font-size:24px;`}>
              <span>We Believe world would be a better place</span>&nbsp;
              <span css={css`font-size:32px;`}>With more <b>Creative</b> Games</span>
            </h1>
          </div>
        </div>
      </div>
    </section>
  )
}
