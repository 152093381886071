/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react'
import { PUBLIC_URL, delay } from '../../Utils/utils';
import Lottie from 'react-lottie';
import loadingAnimation from '../../lotties/loadingScreen.json';
import { Button } from '@mui/material';
const duration = 5000
export default function LoadingScreen() {
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(true)

  const loadingAnimationOptions = {
    loop: false,
    autoplay: true,
    animationData: loadingAnimation,
    role: "presentation",
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid meet'
    }
  };

  useEffect(() => {
    if (loading) {
      const interval = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress < 100 ? prevProgress + 1 : 100
        );
      }, duration / 100);

      return () => {
        clearInterval(interval);
      };
    }
  }, [loading, duration]);


  if (!loading)
    return undefined;
  return (
    <div css={css`  
      position: fixed;
      top:0;
      left:0;
      width: 100vw;
      height: 100vh;
      background-color: #222222;
      z-index: 999999;
      color:white;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
    `}>
      <div className='container'>

        <div style={{ textAlign: 'center' }}>
          <div className='no-mobile'>
            <Lottie
              options={{
                ...loadingAnimationOptions,
              }}
              height={500}
              width={500}
              ariaRole=''
              isClickToPauseDisabled={true}
            />
          </div>
          <div className='mobile-only'>
            <Lottie
              options={loadingAnimationOptions}
              height={350}
              width={350}
              ariaRole=''
              isClickToPauseDisabled={true}
            />
          </div>
          {
            progress < 100 ?
              <div className={progress < 90 ? '' : 'half'}
                css={css`display:flex;flex-direction:column;gap:25px; margin-top:25px;transition:all 1000ms;&.half{margin-top:50px;}`}>
                <div css={css` width: 300px;height: 8px;background-color: darkgray;border-radius: 4px;overflow: hidden;margin:0 auto;`}>
                  <div
                    css={css`height: 100%;
                    background-color: whitesmoke;
                    width: ${progress}%;
                    `}
                  ></div>
                </div>


                <h3>
                  Loading...
                </h3>
              </div>
              : undefined
          }
          <Button className={progress < 100 ? 'hide' : ''}
            css={css`
        padding:20px 40px;background-color:white;color:black;border-radius:10px;font-family:'Tahoma';font-size:20px;font-weight:bold;
        transition: all 500ms;
        cursor: pointer;
        :hover{background-color:lightgray};
        &.hide{
          opacity: 0;
        }
        `}
            onClick={() => setLoading(false)}>
            Proceed To Website
          </Button>
        </div>
      </div>
    </div>
  )
}
