import { Box, Typography, useTheme } from '@mui/material'
import React from 'react'

export default function WorldCreation({ content, setFullScreenSrc }) {
    const theme = useTheme()
    return (
        <Box mt={10}>
            <Typography textAlign={"center"} sx={{ fontSize: { xs: "25px", md: theme.palette.xxl } }}>World Creation</Typography>
            <Typography my={6} sx={{ fontSize: { xs: "20px", lg: theme.palette.m } }}>{content.text}</Typography>
            <div style={{ cursor: "pointer" }} onClick={() => { setFullScreenSrc(content.image) }}>
                <img style={{ width: "100%" }} src={content.image} />
            </div>
        </Box>)
}
