import { PUBLIC_URL } from "../../../Utils/utils";

export const brother = {
    slug: 'brother',
    thumbnail: PUBLIC_URL('/assets/projects/Brother-Thumbnail.jpg'),
    bg: PUBLIC_URL("/assets/projectSingles/backgrounds/brother.png"),
    playIcon: PUBLIC_URL("/assets/projectSingles/play-button.png"),
    video: {
        video: "https://youtu.be/FrLao80irF8",
        typo: PUBLIC_URL("/assets/projectSingles/typographys/brother.png"),
        title: "Short Animation",
        date: "2018",
        // particle: PUBLIC_URL("/assets/projectSingles/sunset/fire-effect-1.png")
    },
    start: {
        text: 'Brother is a short animation about the historical events of the “Battle of Karbala” which was fought on 10 October 680, illustrating Abbas ibn Ali ibn Abi Talib who fought as the standard-bearer of his half-brother Husayn ibn Ali in the Battle of Karbala. He was killed in a desperate attempt to bring water from the Euphrates to quench the unbearable thirst of the besieged family of Muhammad.',
        image: "/assets/projectSingles/brother/03.jpg",
    },
    process: {
        bgParticle: undefined,
        particles: undefined,
        image: "/assets/projectSingles/brother/05.jpg",
        texts: [
            "First we design some concepts for elements and assets that we wanted to be in the scene such as trees animals and etc.",
            "Then we created 3D models based on concepts that were approved to be to our liking, futhermore we pbr textured the models and made them ready to be set in the scene.",
            "We used low poly models with pbr textures and compatibale meshs., how ever it was a personal project yet we tried our best to use our abilities to the maximum."
        ]
    },
    worldCreation: {
        image: "/assets/projectSingles/brother/11.png",
        text: "In “Beauty Cold” we wanted to show the ultimate beauties of winter and inorder to do that we needed a snowy platform with jungle site and few animals to make it look more realistic."
    },
    galleryBgParticle: undefined,
    gallery: [
        "https://youtu.be/V6GwaoK4pzU",
        "https://youtu.be/8Fnw9PifjsQ",
        "https://youtu.be/Z6fGHKB6ReM",
        "https://youtu.be/_gWyanLiY4k",
        "https://youtu.be/tZMCnD4rock"

    ],
    images: [
        PUBLIC_URL("/assets/projectSingles/brother/04.jpg"),
        PUBLIC_URL("/assets/projectSingles/brother/01.jpg"),
        PUBLIC_URL("/assets/projectSingles/brother/06.jpg"),
        PUBLIC_URL("/assets/projectSingles/brother/07.jpg"),
        PUBLIC_URL("/assets/projectSingles/brother/08.jpg"),
        PUBLIC_URL("/assets/projectSingles/brother/09.jpg"),
        PUBLIC_URL("/assets/projectSingles/brother/10.jpg"),
        PUBLIC_URL("/assets/projectSingles/brother/11.jpg"),
        PUBLIC_URL("/assets/projectSingles/brother/12.jpg"),
    ],
};

