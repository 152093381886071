/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Box, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { BG_URL, PUBLIC_URL, isEmptyString } from '../../Utils/utils'
import ReactPlayer from 'react-player'

export default function VideoSection({ content, thumbnail = '', playIcon, setFullScreenSrc }) {
    const theme = useTheme();
    const [showThumbnail, setShowThumbnail] = useState(true);
    useEffect(() => {
        console.log(`thumbnail is=>`, thumbnail);
        const timeout = setTimeout(() => {
            setShowThumbnail(false);
        }, 1500);
        return () => clearTimeout(timeout);
    }, []);
    return (
        <section css={css`
            width:100vw;
            position: relative;
        `}>
            <div css={css`
                width:100%;
                height: 100%;
                top:0;
                left:0;
                position: absolute;
                z-index: 3;
                background-position: center;
                background-size: cover;
                transition: opacity 300ms;
                &.hidden{
                    opacity:0;
                }
            `} style={{ backgroundImage: BG_URL(thumbnail) }} className={isEmptyString(thumbnail) || !showThumbnail ? 'hidden' : ''}>
            </div>
            <ReactPlayer
                url={content.video}
                playing={true}
                width={"100vw"}
                height={"100vh"}
                muted={true}
                controls={true}
            />
            <Box className="text-center" style={{ color: "white", position: "relative" }}>
                <Box className="container" sx={{ position: "relative", zIndex: 4 }}>
                    <img style={{ width: "350px", margin: "0 auto", marginTop: "20px" }} src={content.typo} />
                    <Typography sx={{ fontSize: theme.palette.l }}>{content.title} - {content.date}</Typography>
                </Box>
            </Box>
        </section>
    )
}
