import { PUBLIC_URL } from "../../../Utils/utils";

export const farewell = {
    slug: 'farewell',
    thumbnail: PUBLIC_URL('/assets/projects/farewell-thumbnail.jpg'),
    bg: PUBLIC_URL("/assets/projectSingles/backgrounds/farewell.png"),
    playIcon: PUBLIC_URL("/assets/projectSingles/play-button.png"),
    video: {
        video: "https://youtu.be/u25HarMysX8",
        typo: PUBLIC_URL("/assets/projectSingles/typographys/farewell.png"),
        title: "Short Animation",
        date: "2018",
        particle: undefined
    },
    start: {
        text: "Farewell is a short animation about the historical events of the “Battle of Karbala” which was fought on 10 October 680, illustrating Husayn ibn Ali ibn Abi Talib who was  grandson of the Islamic prophet muhammad. He was killed in the most tragic way by the Yazid army in the Battle of Karbala.",
        image: "/assets/projectSingles/farewell/06.png",
    },
    process: {
        bgParticle: undefined,
        particles: undefined,
        image: "/assets/projectSingles/farewell/07.png",
        texts: [
            "First we design some concepts for enviornments such as the desert, Euphrates river, palm trees and other assets.",
            "Then we created 3D models based on concepts that were approved to be to our liking, futhermore we pbr textured the models and made them ready to be set in the scene.",
            "We used low poly models with pbr textures and compatibale meshs, how ever it was a personal project yet we tried our best to use our abilities to the maximum."
        ]
    },
    worldCreation: {
        bgParticle: undefined,
        text: "In “VR-Karbala” we wanted to create the heavy atmosphere of the tragic battle of karbala and illustrate the grief and swarrow which was burried in the sands of history.",
        image: "/assets/projectSingles/farewell/03.jpg",
    },
    galleryBgParticle: undefined,
    gallery: [
        " https://youtu.be/2Doh3ADRicY",
        " https://youtu.be/rkn-cSafonI",
        " https://youtu.be/yVu_pA3Z1jw",
        " https://youtu.be/e3UAjBr5mVM",
        " https://youtu.be/kG2V_0sOzMM",
        " https://youtu.be/pifcg0jlhF4",
        " https://youtu.be/AUuRfzgGuac"
    ],
    images: [
        PUBLIC_URL("/assets/projectSingles/farewell/01.jpg"),
        PUBLIC_URL("/assets/projectSingles/farewell/02.jpg"),
        PUBLIC_URL("/assets/projectSingles/farewell/03.jpg"),
        PUBLIC_URL("/assets/projectSingles/farewell/04.jpg"),
        PUBLIC_URL("/assets/projectSingles/farewell/05.png"),
        PUBLIC_URL("/assets/projectSingles/farewell/07.png"),
    ]
};
