import { PUBLIC_URL } from "../../../Utils/utils";

export const beautyCold = {
    slug: "beauty-cold",
    thumbnail: PUBLIC_URL('/assets/projects/Beauty-cold.jpg'),
    bg: PUBLIC_URL("/assets/projectSingles/beauty-cold/bg-ice.jpg"),
    playIcon: PUBLIC_URL("/assets/projectSingles/play-button.png"),
    video: {
        video: "https://youtu.be/mam2TDxKbeI",
        typo: PUBLIC_URL("/assets/projectSingles/typographys/beaty-cold.svg"),
        title: "Short Animation",
        date: "2019"
    },
    start: {
        text: "We made this animation in Unreal Engine 4 in order to see how far we can go with the features of this powerful engine.",
        image: PUBLIC_URL("/assets/projectSingles/beauty-cold/banner2.jpg")
    },
    process: {
        particles: undefined,
        texts: [
            "First we designed some concepts for elements and assets that we wanted to be in the scene such as trees animals and etc.",
            "Then we created 3D models based on concepts that were approved to be to our liking, futhermore we pbr textured the models and made them ready to be set in the scene.",
            "We used low poly models with pbr textures and compatibale meshs., how ever it was a personal project yet we tried our best to use our abilities to the maximum."
        ],
        image: PUBLIC_URL("/assets/projectSingles/beauty-cold/banner3.jpg")
    },
    worldCreation: {
        text: "In “Beauty Cold” we wanted to show the ultimate beauties of winter and inorder to do that we needed a snowy platform with jungle site and few animals to make it look more realistic also we should state that this project received Mega Grants from Epic Games.",
        image: PUBLIC_URL("/assets/projectSingles/beauty-cold/banner4.jpg")
    },
    gallery: [
        "https://youtu.be/LZp_zgqb-HI",
        "https://youtu.be/9Fud1oSnC3U",
        "https://youtu.be/P1RFfeNffds",
        "https://youtu.be/oK6Ma5lvG1k",
        "https://youtu.be/K4wnCYBqazE",
    ],
    images: [
        PUBLIC_URL("/assets/projectSingles/beauty-cold/banner4.jpg"),
        PUBLIC_URL("/assets/projectSingles/beauty-cold/video1.jpg"),
        PUBLIC_URL("/assets/projectSingles/beauty-cold/gallery2.jpg"),
        PUBLIC_URL("/assets/projectSingles/beauty-cold/video2.jpg"),
        PUBLIC_URL("/assets/projectSingles/beauty-cold/3.jpg"),
        PUBLIC_URL("/assets/projectSingles/beauty-cold/4.jpg"),
    ],
};