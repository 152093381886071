import { Box, Typography, useTheme } from '@mui/material'
import React from 'react'
import { BG_URL, PUBLIC_URL } from '../../Utils/utils'
import GalleryAndImages from './GalleryAndImages'
import ReactPlayer from 'react-player'
import { hasPointerEvents } from '@testing-library/user-event/dist/utils'

export default function Tide({ project, setFullScreenSrc }) {
    const theme = useTheme()
    return (
        <Box sx={{
            backgroundImage: BG_URL(project.bg),
            backgroundPosition: "center",
            backgroundSize: "cover",
            color: "white",
            width: "100%",
            p: 0,
            m: 0
        }}>

            {/* video section */}
            <div style={{ pointerEvents: "none" }}>
                <ReactPlayer
                    url={project.video.video}
                    playing={true}
                    width={"100vw"}
                    height={"100vh"}
                    muted={true}
                    controls={false}
                    config={{
                        youtube: {
                            playerVars: { showinfo: 1 }
                        },
                    }}
                />
            </div>
            {/* typo section */}
            <Box className="text-center" style={{ color: "white", position: "relative" }}>
                {project.video.particle ?
                    <img src={project.video.particle} style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        zIndex: 0,
                        width: "100%"
                    }} />
                    :
                    undefined
                }
                <Box className="container" sx={{ position: "relative", zIndex: 4 }}>
                    <div style={{ cursor: "pointer" }} onClick={() => { setFullScreenSrc(project.video.typo) }}>
                        <img style={{ width: "350px", margin: "0 auto", marginTop: "20px" }} src={project.video.typo} />
                    </div>
                    <Typography sx={{ fontSize: theme.palette.l }}>Third-Person Shooter 2021-2023</Typography>
                </Box>
            </Box>
            {/* explanation */}
            <Box mt={10} className="container" sx={{ position: "relative", zIndex: 4 }}>
                <Typography textAlign={"center"} sx={{ fontSize: { xs: "25px", md: theme.palette.xxl } }}>Brief Project Explanation</Typography>
                <Typography my={3} sx={{ fontSize: { xs: "20px", lg: theme.palette.m } }}>
                    We cooperated with Stanion Studios on their game named “TideTurn” a third person shooter illustrating a fight between aqua creatures and humans. Now available on Steam and Consoles, here are the things we did for Stanions Studios:
                </Typography>
                <Typography my={1} sx={{ fontSize: { xs: "20px", lg: theme.palette.m } }}>1. Character Look Development</Typography>
                <Typography my={1} sx={{ fontSize: { xs: "20px", lg: theme.palette.m } }}>2. Cinematics for each character</Typography>
                <Typography my={1} sx={{ fontSize: { xs: "20px", lg: theme.palette.m } }}>3. Concept arts</Typography>
                <div style={{ cursor: "pointer" }} onClick={() => { setFullScreenSrc(project.explanation.image) }}>
                    <img style={{ width: "100%", aspectRatio: "16/9" }} src={project.explanation.image} />
                </div>
            </Box>
            {/* process */}
            <Box mt={10} sx={{ position: "relative" }}>
                <Box className="container" sx={{ position: "relative", zIndex: 2 }}>
                    <Typography textAlign={"center"} sx={{ fontSize: { xs: "25px", md: theme.palette.xxl } }}>Look Development Process</Typography>

                    <Box className="row" sx={{ alignItems: "center" }}>
                        <Box className='col-9'>
                            <Typography my={6} sx={{ fontSize: { xs: "20px", lg: theme.palette.m } }}>{project.process.texts[0]}</Typography>
                        </Box>
                        <Box className='col-3'>
                            <div style={{ cursor: "pointer" }} onClick={() => { setFullScreenSrc(project.process.particles[0]) }}>
                                <img style={{ width: "100%" }} src={project.process.particles[0]} />
                            </div>
                        </Box>
                    </Box>

                    <Box className="row" sx={{ alignItems: 'center' }}>
                        <Box className='col-2'>
                            <div style={{ cursor: "pointer" }} onClick={() => { setFullScreenSrc(project.process.particles[1]) }}>
                                <img style={{ width: "100%" }} src={project.process.particles[1]} />
                            </div>
                        </Box>
                        <Typography className="col-10" mb={6} mt={1} sx={{ fontSize: { xs: "20px", lg: theme.palette.m } }}>{project.process.texts[1]}</Typography>
                    </Box>

                    <Typography className="col-10" my={6} sx={{ fontSize: { xs: "20px", lg: theme.palette.m } }}>{project.process.texts[2]}</Typography>
                    <div style={{ cursor: "pointer" }} onClick={() => { setFullScreenSrc(project.process.image) }}>
                        <img style={{ width: "100%", aspectRatio: "16/9" }} src={project.process.image} />
                    </div>
                </Box>
            </Box>
            {/* character */}
            <Box mt={10} sx={{ position: "relative" }}>
                <Box className="container" sx={{ position: "relative", zIndex: 2 }}>
                    <Typography textAlign={"center"} sx={{ fontSize: { xs: "25px", md: theme.palette.xxl } }}>Character Development</Typography>

                    <Box className="row" sx={{ alignItems: "center" }}>
                        <Box className='col-8' sx={{ pr: 0 }}>
                            <Typography my={6} sx={{ fontSize: { xs: "20px", lg: theme.palette.m } }}>{project.character.texts[0]}</Typography>
                        </Box>
                        <Box className='col-4' sx={{ pl: 0 }}>
                            <div style={{ cursor: "pointer" }} onClick={() => { setFullScreenSrc(project.character.particles[0]) }}>
                                <img style={{ width: "100%", marginLeft: "-30px" }} src={project.character.particles[0]} />
                            </div>
                        </Box>
                    </Box>

                    <Typography className="col-10" my={6} sx={{ fontSize: { xs: "20px", lg: theme.palette.m } }}>{project.character.texts[1]}</Typography>
                    <div style={{ cursor: "pointer" }} onClick={() => { setFullScreenSrc(project.character.particles[1]) }}>
                        <img style={{ width: "100%", aspectRatio: "16/9" }} src={project.character.particles[1]} />
                    </div>
                </Box>
            </Box>

            {/* art */}
            <Box mt={10} className="container" sx={{ position: "relative", zIndex: 4 }}>
                <Typography textAlign={"center"} sx={{ fontSize: { xs: "25px", md: theme.palette.xxl } }}>Concept Arts</Typography>
                <Typography mt={3} sx={{ fontSize: { xs: "20px", lg: theme.palette.m } }}>
                    We produced some sketches for characters skin based on Stanion Studios requests.
                </Typography>
                <Typography mb={3} sx={{ fontSize: { xs: "20px", lg: theme.palette.m } }}>
                    The list starts with SteamPunk Henry:
                </Typography>

                <div className='row'>
                    {project.conceptArt.sketches.map((image, index) => {
                        return <div key={index} className='col-12 col-md-4'>
                            <div style={{ cursor: "pointer" }} onClick={() => { setFullScreenSrc(image) }}>
                                <img style={{ width: "100%" }} src={image} />
                            </div>
                        </div>
                    })}
                    <Typography my={3} sx={{ color: "#8c949b", fontSize: { xs: "20px", lg: theme.palette.m }, textAlign: 'center' }}>Set of sketches</Typography>
                    <div style={{ cursor: "pointer" }} onClick={() => { setFullScreenSrc(PUBLIC_URL("/assets/projectSingles/tide-turn/07.jpg")) }}>
                        <img style={{ width: "100%" }} src={PUBLIC_URL("/assets/projectSingles/tide-turn/07.jpg")} />
                    </div>
                    <Typography my={3} sx={{ color: "#8c949b", fontSize: { xs: "20px", lg: theme.palette.m }, textAlign: 'center' }}>Final Version</Typography>
                    <div className='row'>
                        {project.conceptArt.finalVersions.map((image, index) => {
                            return <div className='col-12 col-md 6' key={index}>
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                    <div style={{ cursor: "pointer" }} onClick={() => { setFullScreenSrc(image) }}>
                                        <img style={{ width: "100%" }} src={image} />
                                    </div>
                                    <Typography my={3} sx={{ color: "#8c949b", fontSize: { xs: "20px", lg: theme.palette.m }, textAlign: 'center' }}>Final Version</Typography>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </Box>
            {/* cinematics */}
            <Box mt={10} sx={{ position: "relative" }}>
                <Box className="container" sx={{ position: "relative", zIndex: 2 }}>
                    <Typography textAlign={"center"} sx={{ fontSize: { xs: "25px", md: theme.palette.xxl } }}>Cinematics</Typography>
                    <Typography my={6} sx={{ fontSize: { xs: "20px", lg: theme.palette.m } }}>{project.cinematics.text}</Typography>
                    <div style={{ display: "flex", flexDirection: "column", gap: "100px" }}>
                        {project.cinematics.videos.map((video, index) => {
                            return <Box key={index} sx={{
                                height: "100vh",
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center"
                            }} >
                                <div style={{ paddingTop: "40px" }}>
                                    <ReactPlayer
                                        url={video}
                                        playing={false}
                                        width={"80vw"}
                                        height={"100vh"}
                                        controls={true}
                                    />
                                </div>
                                {/* <div style={{ cursor: "pointer" }} onClick={() => { setFullScreenSrc(project.playIcon) }}>
                                    <img sx={{ width: "200px" }} src={project.playIcon} />
                                </div> */}
                            </Box>
                        })}
                    </div>

                </Box>
            </Box>
            {/* gallery */}
            <GalleryAndImages setFullScreenSrc={setFullScreenSrc} gallery={project.gallery} images={project.images} galleryBgParticle={project.galleryBgParticle} playIcon={project.playIcon} />

        </Box >

    )
}
