import { PUBLIC_URL } from "../../../Utils/utils";
export const imc = {
    slug: 'imc',
    thumbnail: PUBLIC_URL('/assets/projects/imc.jpg'),
    bg: PUBLIC_URL("/assets/projectSingles/backgrounds/back-imc.png"),
     playIcon: PUBLIC_URL("/assets/projectSingles/play-button.png"),
     video: {
        video: "https://youtu.be/nbednk_uiyQ",
        typo: PUBLIC_URL("/assets/projectSingles/typographys/overload.png"),
    },
     explanation: {
        image: "/assets/projectSingles/imc/02.jpg",
        text: 'We are delighted to announce our collaboration with Ablizmo Studio on their exciting game, "Overload IMC." This action-packed real-time tactics strategy game incorporates rogue-like elements, challenging players to mine materials relentlessly in order to save humanity.'
    },
     wwd: {
        image: "/assets/projectSingles/imc/03.jpg",
    },
     conceptArt: {
        sketches: [
            PUBLIC_URL("/assets/projectSingles/imc/sketch1.png"),
            PUBLIC_URL("/assets/projectSingles/imc/sketch2.png"),
        ],
        finalVersions: [
            PUBLIC_URL("/assets/projectSingles/imc/01.jpg"),
            PUBLIC_URL("/assets/projectSingles/imc/05.jpg")
        ],
    },
     models: {
        images: [
            PUBLIC_URL("/assets/projectSingles/imc/06.jpg"),
            PUBLIC_URL("/assets/projectSingles/imc/08.jpg"),
        ],
        text: "To meet our environmental needs, we required a new set of assets that would align with our concepts. However, these assets needed to be low-poly in order to optimize them for the game. As a result, we designed various concepts and created corresponding 3D models to suit the different biomes within the game."
    },
     design: {
        image: PUBLIC_URL("/assets/projectSingles/imc/07.jpg"),
        text: "After creating the 3D objects, the only remaining task was to design them within the different biomes to complete the   This final step allowed us to showcase the outcome of our hard work!"
    },
     gallery: [
        "https://youtu.be/f8EkyQZ98kA",
        "https://youtu.be/sCTxTAC0S9w",
        "https://youtu.be/iEquWg7Mxog",
        "https://youtu.be/oqO2faYUX64",
        "https://youtu.be/SOJHINp3jK0",
        "https://youtu.be/QBejgkSllvs",
        "https://youtu.be/aPspWiLSGl8"
    ],
     images: [
        PUBLIC_URL("/assets/projectSingles/imc/10.jpg"),
        PUBLIC_URL("/assets/projectSingles/imc/11.jpg"),
        PUBLIC_URL("/assets/projectSingles/imc/12.jpg"),
        PUBLIC_URL("/assets/projectSingles/imc/13.jpg"),
        PUBLIC_URL("/assets/projectSingles/imc/14.jpg"),
        PUBLIC_URL("/assets/projectSingles/imc/15.jpg"),
        PUBLIC_URL("/assets/projectSingles/imc/16.jpg"),
        PUBLIC_URL("/assets/projectSingles/imc/17.jpg"),
        PUBLIC_URL("/assets/projectSingles/imc/18.jpg"),
        PUBLIC_URL("/assets/projectSingles/imc/19.jpg"),
        PUBLIC_URL("/assets/projectSingles/imc/20.jpg"),
    ]
};