import { PUBLIC_URL } from "../../../Utils/utils";
export const vrHaj = {
    slug: 'vr-haj',
    thumbnail: PUBLIC_URL('/assets/projects/vr-hajj.jpg'),
    bg: PUBLIC_URL("/assets/projectSingles/backgrounds/vr-haj.png"),
    playIcon: PUBLIC_URL("/assets/projectSingles/play-button.png"),
    video: {
        video: "https://youtu.be/Na-_6R9iP60",
        typo: PUBLIC_URL("/assets/projectSingles/typographys/haj.svg"),
        title: "VR Animation",
        date: "2020",
        // particle: PUBLIC_URL("/assets/projectSingles/sunset/fire-effect-1.png")
    },

    start: {
        text: "“VR Haj” was a VR animation that we created in 2020, In this VR u can enjoy visiting  Kaaba “House of Allah” in the sacred city of Mecca in Saudi Arabia.Hajj is an annual practice when Muslim brotherhood is on display and their solidarity with fellow Muslim people, and submission to God (Allah) is fulfilled.",
        image: "/assets/projectSingles/vr-haj/04.jpg",
    },
    process: {
        bgParticle: undefined,
        particles: undefined,
        image: "/assets/projectSingles/vr-haj/06.jpg",
        texts: [
            "Initially, we sourced 3D models of the Kaaba from reputable online sources.",
            "Subsequently, we meticulously textured and prepared these models, ensuring their seamless integration into the scene.",
            "Then came in the lighting process, VR Hajj has two lighting modes , night and day light"
        ]
    },
    crowd: {
        // bgParticle: PUBLIC_URL("/assets/projectSingles/sunset/fire-effect-2.png"),
        text: 'In the development of "VR Hajj," we successfully implemented a crowd system using Houdini and Unreal Engine, along with the Niagara particle system. This meticulously designed blueprint enables the creation of a realistic crowd of people, operating at a smooth 90 frames per second. We have also prioritized optimization specifically for VR projects, ensuring an immersive and seamless experience.',
        image: "/assets/projectSingles/vr-haj/11.jpg",
    },
    //  galleryBgParticle  : PUBLIC_URL("/assets/projectSingles/sunset/fire-effect-4.png")
    gallery: [
        "https://youtu.be/YdDznYs4Qw4",
        "https://youtu.be/MC75eO0OTFQ",
        "https://youtu.be/I8ivIgrq24M",
        "https://youtu.be/rhgYHMJfByM",
        "https://youtu.be/Na-_6R9iP60",
    ],
    images: [
        PUBLIC_URL("/assets/projectSingles/vr-haj/02.jpg"),
        PUBLIC_URL("/assets/projectSingles/vr-haj/10.jpg"),
        PUBLIC_URL("/assets/projectSingles/vr-haj/03.jpg"),
        PUBLIC_URL("/assets/projectSingles/vr-haj/08.jpg"),
        PUBLIC_URL("/assets/projectSingles/vr-haj/05.jpg"),
        PUBLIC_URL("/assets/projectSingles/vr-haj/07.jpg"),
        PUBLIC_URL("/assets/projectSingles/vr-haj/09.jpg"),
    ]
};