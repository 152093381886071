import React from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import { isEmptyString } from '../../Utils/utils'

export default function Crowd({ content, setFullScreenSrc }) {
  const theme = useTheme()
  if (!content) return undefined
  else return (
    <Box className="container" mt={10}>
      <Typography textAlign={"center"} sx={{ fontSize: { xs: "25px", md: theme.palette.xxl } }}>Crowd System</Typography>
      <Typography my={6} sx={{ fontSize: { xs: "20px", lg: theme.palette.m } }}>{content.text}</Typography>
      <div style={{ cursor: "pointer" }} onClick={() => { setFullScreenSrc(content.image) }}>
        <img style={{ width: "100%" }} src={content.image} />
      </div>
    </Box>
  )
}
