import { motion } from "framer-motion";


export default function AnimateBox({ children, component }) {
    return <motion.div
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.5 }}
    >
        <div />
        <motion.div variants={{
            offscreen: {
                y: 400
            },
            onscreen: {
                y: component == "project" ? 0 : component == "mission" ? 50 : component == "services" ? 100 : component == "team" ? 100 : 0,
                transition: {
                    type: "spring",
                    bounce: 0.4,
                    duration: 1
                }
            }
        }}>
            {children}
        </motion.div>
    </motion.div >
}
