/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Box, Stack, Typography, useTheme } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import { PUBLIC_URL } from '../../Utils/utils'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Lottie from 'react-lottie';
import animationData from '../../lotties/bubbles.json';
import computerAnimationData from '../../lotties/computer.json';
import AnimateBox from '../../Components/Animations/AnimateBox';

export default function AboutOurServices({ }) {
    const theme = useTheme()
    const bubblesOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid meet'
        }
    };
    const bubblesOptions2 = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid meet'
        }
    };
    const computerAnimationOptions = {
        loop: true,
        autoplay: true,
        animationData: computerAnimationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid meet'
        }
    };
    return (
        <Box id="Services" sx={{
            scrollSnapAlign: "center",
            background: "rgb(38,58,174)",
            background: "linear-gradient(180deg, rgba(38,58,174,1) 8%, rgba(29,88,234,1) 100%)",
            position: "relative",
            minHeight: "100vh",
            my: "-1px",
            zIndex: 2,
        }}>
            <Box className="container no-mobile">
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <Box sx={{ color: "white", paddingTop: { xs: "15vh", md: "10vh" }, mx: "20px", flex: 1, position: 'relative' }}>
                        <AnimateBox component={"services"}>
                            {/* <AnimationOnScroll animateIn='animate__fadeIn' > */}
                            <Typography component="div" css={css`font-size:24px;line-height:10px;font-weight:300;`}>About</Typography>
                            <Typography component="div" css={css`font-size:34px;font-weight:700;`}>Our Services</Typography>
                            {/* </AnimationOnScroll> */}
                            <Stack spacing={2} sx={{ marginTop: "5vh", height: "fit-content" }} >
                                <ServicesTexts index={2} fontSize={26}>Scene layout and design</ServicesTexts>
                                <ServicesTexts index={3} fontSize={26}>VR, Console, & PC Productions</ServicesTexts>
                                <ServicesTexts index={4} fontSize={26}>Unreal and Unity specialist</ServicesTexts>
                                <ServicesTexts index={5} fontSize={26}>Asset Creation</ServicesTexts>
                                <ServicesTexts index={6} fontSize={26}>Look Development</ServicesTexts>
                                <ServicesTexts index={8} fontSize={26}>3D Prototyping</ServicesTexts>
                                <ServicesTexts index={7} fontSize={26}>Hard Surface and Organic Modeling</ServicesTexts>
                                <ServicesTexts index={9} fontSize={26}>PBR Texturing</ServicesTexts>
                            </Stack>
                        </AnimateBox>
                        <Box sx={{ position: "absolute", bottom: "-10%", left: "-20%" }}>
                            <Lottie
                                options={bubblesOptions}
                                height={200}
                                width={200}
                                ariaRole=''
                                isClickToPauseDisabled={true}
                            />
                        </Box>
                    </Box>
                    <div css={css`height: 500px;flex:1;display: flex;align-self: flex-end;position: relative;cursor: none;`}>
                        <Lottie
                            options={computerAnimationOptions}
                            height={600}
                            width={600}
                            ariaRole=''
                            isClickToPauseDisabled={true}
                            style={{
                                position: "relative",
                                zIndex: "10",
                            }}
                        />
                        <div style={{
                            position: "absolute",
                            top: "0",
                            right: "0",
                            zIndex: "0",
                            cursor: "none",
                            // transform: "translate(-50%,-50%)",
                        }} >
                            <Lottie
                                options={bubblesOptions2}
                                height={500}
                                width={500}
                                ariaRole=''
                                isClickToPauseDisabled={true}
                            />
                        </div>
                        <div style={{
                            position: "absolute",
                            bottom: "0",
                            left: "0",
                            zIndex: "0"
                            // transform: "translate(-50%,-50%)",
                        }} >
                            <Lottie
                                options={bubblesOptions}
                                height={200}
                                width={200}
                                ariaRole=''
                                isClickToPauseDisabled={true}
                            />
                        </div>
                    </div>
                </Box>
            </Box>
            <Box className="container mobile-only" sx={{ width: "100%", position: "relative", marginBottom: "-100px" }}>
                <div css={css`position:relative;`}>
                    <Lottie
                        options={computerAnimationOptions}
                        height={350}
                        width={350}

                    />
                    <Lottie
                        options={bubblesOptions}
                        height={200}
                        width={200}
                        style={{ position: "absolute", top: 0, right: 0 }}
                    />
                    <Lottie
                        options={bubblesOptions}
                        height={200}
                        width={200}
                        style={{ position: "absolute", bottom: 0, left: 0 }}
                    />
                </div>
                <Typography component="div" sx={{ color: "white", fontWeight: "bold", fontSize: "26px", pb: 3, width: "90%", margin: "0 auto" }}>Our Services</Typography>
                <Stack spacing={1} sx={{ position: "relative", zIndex: 2, width: "90%", margin: "0 auto" }}>
                    <Typography color={"white"} fontSize={17}>Scene layout and design</Typography>
                    <Typography color={"white"} fontSize={17}>VR, Console, & PC Productions</Typography>
                    <Typography color={"white"} fontSize={17}>Unreal and Unity specialist</Typography>
                    <Typography color={"white"} fontSize={17}>Asset Creation</Typography>
                    <Typography color={"white"} fontSize={17}>Look Development</Typography>
                    <Typography color={"white"} fontSize={17}>Hard Surface and Organic Modeling</Typography>
                    <Typography color={"white"} fontSize={17}>3D Prototyping</Typography>
                    <Typography color={"white"} fontSize={17}>PBR Texturing</Typography>
                </Stack>
                <Box sx={{ position: "absolute", bottom: "10%", right: "5%" }}>
                    <Lottie
                        options={bubblesOptions}
                        height={150}
                        width={150}
                    />
                </Box>
            </Box>
        </Box >)
}
const ServicesTexts = ({ children, fontSize, index }) => {
    console.log(index)
    return (
        // <AnimationOnScroll animateIn='animate__fadeIn' delay={300 * index}>
        <Typography css={css`font-weight:200;`} fontSize={fontSize}>{children}</Typography>
        // </AnimationOnScroll>
    )
}