import { Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { BG_URL, PUBLIC_URL, isEmptyString } from '../../Utils/utils'
import Swiper from 'react-id-swiper';
import 'swiper/swiper.css'
import ReactPlayer from 'react-player';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function GalleryAndImages({ gallery, images, playIcon, galleryBgParticle, setFullScreenSrc }) {
    const theme = useTheme()
    return (
        <>
            <Box pb={"10vh"} mt={10} sx={{ position: "relative" }}>
                {!isEmptyString(galleryBgParticle) ?
                    <img src={galleryBgParticle} style={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        zIndex: 0,
                        width: "100%",
                        height: "100%"
                    }} />
                    :
                    undefined
                }
                <Box className="container">
                    <Box sx={{ position: "relative", zIndex: 3 }}>
                        <GallerySlider content={gallery} />
                        <Images content={images} setFullScreenSrc={setFullScreenSrc} />
                    </Box>
                </Box>
            </Box>
        </>
    )
}


const GallerySlider = ({ content }) => {
    const settings = {
        arrows: true,
        dots: true,
        slidesToShow: 5,
        slidesToScroll: 5,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                }
            }
        ],
    }

    return (
        <Slider {...settings}>
            {content.map((url, index) => {
                return <Box key={index} p={2}>
                    <ReactPlayer
                        key={index}
                        url={url}
                        playing={false}
                        width={"100%"}
                        height={"200px"}
                        controls={true}
                    />
                </Box>
            })}

        </Slider>

    )
};


function Images({ content, setFullScreenSrc }) {
    const theme = useTheme()
    return (
        <Box className="row" >
            <Typography textAlign={"center"} sx={{ fontSize: { xs: "25px", md: theme.palette.xxl, fontWeight: 'bold' }, m: 3 }}>Images</Typography>
            {content.map((bg, index) => {
                return <div className="col-4" key={index} onClick={() => { setFullScreenSrc(bg) }}>
                    <Box sx={{
                        background: BG_URL(bg),
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        display: "flex",
                        alignItems: 'center',
                        justifyContent: 'center',
                        aspectRatio: "16/9",
                        mx: "auto",
                        my: 3,
                        cursor: "pointer"
                    }} />
                </div>
            })}
        </Box>
    )
}
