import { Box, Typography, useTheme } from '@mui/material'
import React from 'react'
import { BG_URL, PUBLIC_URL } from '../../Utils/utils'
import GalleryAndImages from './GalleryAndImages'
import ReactPlayer from 'react-player'

export default function Imc({ project, setFullScreenSrc }) {
    const theme = useTheme()
    return (
        <Box sx={{
            backgroundImage: BG_URL(project.bg),
            backgroundPosition: "center",
            backgroundSize: "cover",
            color: "white",
            width: "100%",
            p: 0,
            m: 0
        }}>

            {/* video section */}
            <ReactPlayer
                url={project.video.video}
                playing={true}
                width={"100vw"}
                height={"100vh"}
                muted={false}
                controls={true}
            />
            {/* typo section */}
            <Box className="text-center" style={{ color: "white", position: "relative" }}>
                {project.video.particle ?
                    <img src={project.video.particle} style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        zIndex: 0,
                        width: "100%"
                    }} />
                    :
                    undefined
                }
                <Box className="container" sx={{ position: "relative", zIndex: 4 }}>
                    <img style={{ width: "350px", margin: "0 auto", marginTop: "20px" }} src={project.video.typo} />
                    <Typography sx={{ fontSize: theme.palette.l }}>Action Strategy Game</Typography>
                </Box>
            </Box>
            {/* explanation */}
            <Box mt={10} className="container" sx={{ position: "relative", zIndex: 4 }}>
                <Typography textAlign={"center"} sx={{ fontSize: { xs: "25px", md: theme.palette.xxl } }}>Project Explanation</Typography>
                <Typography my={3} sx={{ fontSize: { xs: "20px", lg: theme.palette.m } }}>{project.explanation.text}</Typography>
                <div style={{ cursor: "pointer" }} onClick={() => { setFullScreenSrc(project.explanation.image) }}>
                    <img style={{ width: "100%", aspectRatio: "16/9" }} src={project.explanation.image} />
                </div>
            </Box>
            {/* What we did */}
            <Box mt={10} className="container" sx={{ position: "relative", zIndex: 4 }}>
                <Typography textAlign={"center"} sx={{ fontSize: { xs: "25px", md: theme.palette.xxl } }}>What We Did</Typography>
                <Typography my={1} sx={{ fontSize: { xs: "20px", lg: theme.palette.m } }}>1. Vast variation of concepts for their enviornment designs</Typography>
                <Typography my={1} sx={{ fontSize: { xs: "20px", lg: theme.palette.m } }}>2. 3D Modeling</Typography>
                <Typography mb={5} sx={{ fontSize: { xs: "20px", lg: theme.palette.m } }}>3. Environment design</Typography>
                <div style={{ cursor: "pointer" }} onClick={() => { setFullScreenSrc(project.wwd.image) }}>
                    <img style={{ width: "100%", aspectRatio: "16/9" }} src={project.wwd.image} />
                </div>
            </Box>

            {/* art */}
            <Box mt={10} className="container" sx={{ position: "relative", zIndex: 4 }}>
                <Typography textAlign={"center"} sx={{ fontSize: { xs: "25px", md: theme.palette.xxl } }}>Concept Arts</Typography>
                <Typography mt={3} sx={{ fontSize: { xs: "20px", lg: theme.palette.m } }}>
                    In this project, we have designed a range of concepts for both the interior and exterior environments. Additionally, we have created a diverse selection of assets for different biomes, including jungle, lava, ice, and more. Below, we proudly present a showcase of these creations:
                </Typography>

                <div className='row'>
                    {project.conceptArt.sketches.map((image, index) => {
                        return <div key={index} style={{ cursor: "pointer" }} className='col-12 col-md-6' onClick={() => { setFullScreenSrc(image) }}>
                            <img style={{ width: "100%", marginBottom: "40px" }} src={image} />
                        </div>
                    })}
                    {project.conceptArt.finalVersions.map((image, index) => {
                        return <div key={index}>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", cursor: "pointer" }}
                                onClick={() => { setFullScreenSrc(image) }}
                            >
                                <img style={{ width: "100%" }} src={image} />
                            </div>
                        </div>
                    })}
                </div>
            </Box>
            {/* models */}
            <Box mt={10} className="container" sx={{ position: "relative", zIndex: 4 }}>
                <Typography textAlign={"center"} sx={{ fontSize: { xs: "25px", md: theme.palette.xxl } }}>3D Models</Typography>
                <Typography my={3} sx={{ fontSize: { xs: "20px", lg: theme.palette.m } }}>
                    {project.design.text}
                </Typography>
                <div style={{ cursor: "pointer" }} onClick={() => { setFullScreenSrc(project.models.images[0]) }}>
                    <img style={{ width: "100%", marginBottom: "20px" }} src={project.models.images[0]} />
                </div>
                <div style={{ cursor: "pointer" }} onClick={() => { setFullScreenSrc(project.models.images[1]) }}>
                    <img style={{ width: "100%" }} src={project.models.images[1]} />
                </div>

            </Box>

            {/*  */}
            <Box mt={10} sx={{ position: "relative" }}>
                <Box className="container" sx={{ position: "relative" }}>
                    <Typography textAlign={"center"} sx={{ fontSize: { xs: "25px", md: theme.palette.xxl } }}>Enviornment Design</Typography>
                    <Typography my={6} sx={{ fontSize: { xs: "20px", lg: theme.palette.m } }}>{project.design.text}</Typography>
                    <div style={{ cursor: "pointer" }} onClick={() => { setFullScreenSrc(project.design.image) }}>
                        <img style={{ width: "100%", aspectRatio: "16/9" }} src={project.design.image} />
                    </div>
                </Box>
            </Box>
            {/* gallery */}
            <GalleryAndImages setFullScreenSrc={setFullScreenSrc} gallery={project.gallery} images={project.images} galleryBgParticle={project.galleryBgParticle} playIcon={project.playIcon} />

        </Box >

    )
}
