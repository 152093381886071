/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Box, Typography, useTheme } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { BG_URL, PUBLIC_URL } from '../../Utils/utils'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie';
import baloons from '../../lotties/baloon.json';
import clouds from '../../lotties/clouds.json';
import clouds1 from '../../lotties/clouds1.json';
import clouds2 from '../../lotties/clouds2.json';
import AnimateBox from '../../Components/Animations/AnimateBox';
import "./Landing.css"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { PROJECTS } from '../../Components/App/data';
import { PROJECTS } from '../ProjectSingle/data/all_projects';

export default function OurProjects({ }) {
    const [x, setX] = useState("87vw");
    const [x2, setX2] = useState("15vw");
    const [top, setTop] = useState("15vw");
    const theme = useTheme();
    const navigate = useNavigate();
    useEffect(() => {
        setX("11vw");
        setX2("12vw");
        const interval = setInterval(() => {
            setX(prev => prev == "11vw" ? "87vw" : "11vw");
        }, 60000);

        const interval2 = setInterval(() => {
            setX2(prev => prev == "16vw" ? "12vw" : "16vw");
        }, 20000);
        const interval3 = setInterval(() => {
            setTop(prev => prev == "30vh" ? "32vh" : "30vh");
        }, 10000);
        return () => {
            clearInterval(interval);
            clearInterval(interval2);
            clearInterval(interval3);
        }
    }, [])

    const baloonsOptions = {
        loop: false,
        autoplay: false,
        animationData: baloons,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid meet'
        }
    };
    // middle cloud
    const cloudsOptions = {
        loop: true,
        autoplay: true,
        animationData: clouds,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid meet'
        }
    };
    // right cloud
    const clouds1Options = {
        loop: true,
        autoplay: true,
        animationData: clouds1,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid meet'
        }
    };
    // left cloud
    const clouds2Options = {
        loop: true,
        autoplay: true,
        animationData: clouds2,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid meet'
        }
    };


    return (
        <section id={"Projects"} style={{
            scrollSnapAlign: "center",
            minHeight: "100vh",
            height: "100vh",
            maxHeight: "100vh",
            overflow: 'hidden',
            background: "rgb(55,149,216)",
            background: "linear-gradient(180deg, rgba(55,149,216,1) 15%, rgba(139,210,244,1) 100%)",
            paddingBottom: "100px"
        }}>
            {/* shadow box */}
            <Box sx={{ width: "100%", boxShadow: "0px 0px 15px 10px rgba(0,0,0,0.6)" }} />
            {/* clouds box */}
            <Box sx={{ width: "100%", position: "relative", height: { xs: "10vh", lg: "35vh" } }}>

                {/* top left cloud */}
                <div className='no-mobile' style={{
                    width: "25%",
                    position: "absolute",
                    left: "-5vw",
                    top: "-10vh"
                }} >
                    <Lottie
                        options={clouds2Options}
                        height={500}
                        width={500}
                    />
                </div>
                <div className='mobile-only' style={{
                    width: "50%",
                    position: "absolute",
                    left: "0vw",
                    top: "-7vh"
                }} >
                    <Lottie
                        options={clouds2Options}
                        height={200}
                        width={200}
                    />
                </div>

                {/* small balloon */}

                <img className='no-mobile' style={{ transition: "20000ms", width: "50px", position: "absolute", top: top, right: x2, }} src={PUBLIC_URL("/assets/balloon-small.png")} />
                <img className='mobile-only' style={{ width: "25px", position: "absolute", top: "15vh", right: x2, transition: "20000ms" }} src={PUBLIC_URL("/assets/balloon-small.png")} />


                {/* right top cloud */}
                <div className='no-mobile' style={{
                    width: "20%",
                    position: "absolute",
                    right: "5vw",
                    top: "-15vh"
                }} >
                    <Lottie
                        options={clouds1Options}
                        height={500}
                        width={500}
                    />
                </div>
                <div className='mobile-only' style={{
                    width: "20%",
                    position: "absolute",
                    right: "20vw",
                    top: "-5vh"
                }} >
                    <Lottie
                        options={clouds1Options}
                        height={200}
                        width={200}
                    />
                </div>
                {/* big cloud */}
                <div className='no-mobile' style={{
                    width: "60%",
                    position: "absolute",
                    top: "-25vh",
                    left: "50%",
                    transform: "translateX(-50%)"
                }}>
                    <Lottie
                        options={clouds2Options}
                        height={800}
                        width={1000}
                    />
                </div>

                <div className='mobile-only' src={PUBLIC_URL("/assets/cloud-4.png")} style={{
                    position: "absolute",
                    top: "-3vh",
                    right: "20vw",
                }} >
                    <Lottie
                        options={clouds2Options}
                        height={300}
                        width={300}
                    />
                </div>

                {/* particles */}
                <img src={PUBLIC_URL("/assets/sky-dots.png")} style={{
                    width: "100%",
                    position: "absolute",
                    zIndex: 1,
                    top: 20,
                    right: "10%",
                    opacity: "0.2",
                    filter: "grayscale(100%)"
                }} />


                {/* red baloon */}
                <div className='no-mobile' style={{
                    width: "10%",
                    position: "absolute",
                    zIndex: 1,
                    top: "6vh",
                    left: x,
                    transition: "80000ms",
                }} >
                    <Lottie
                        options={baloonsOptions}
                        height={200}
                        width={200}
                        ariaRole=''
                        isClickToPauseDisabled={true}
                    />
                </div>

                <div className='mobile-only' style={{
                    width: "25%",
                    position: "absolute",
                    zIndex: 1,
                    top: "4vh",
                    left: x,
                    transition: "80000ms"
                }}>
                    <Lottie
                        options={baloonsOptions}
                        height={100}
                        width={50}
                        ariaRole=''
                        isClickToPauseDisabled={true}
                    />
                </div>
            </Box>

            {/* text box */}
            <Box css={css`display:flex;
            padding: 0 10vw;
            align-items:center;
            justify-content: center;
            position:relative;
            z-index:10;
            text-align:justify;
            @media (max-width:1200px) {padding-top:200px;}
            @media (max-width:600px) {padding-top:100px;}`}>
                <Box className='container'>
                    <AnimateBox component={"project"}>
                        <Typography css={css`font-size:36px;font-weight:800;color:white;
                            @media (max-width:600px) {font-size:18px;}`}>
                            Our Projects
                        </Typography>
                    </AnimateBox>
                    <AnimateBox component={"project"}>
                        <Typography css={css`color:white;font-size:20px;font-weight:200;margin-top:26px;
                            @media (max-width:600px) {font-size:14px;}`}>
                            Step right into the extraordinary <b>world of wonders!</b> We are thrilled to unveil our captivating portfolio and showcase our remarkable achievements thus far. <b>Get ready</b> to embark on an exhilarating journey as you dive into the depths of our creations. <b>Take your time,</b> wander freely, and let your curiosity guide you through this awe-inspiring experience.
                        </Typography>
                    </AnimateBox>
                </Box>
            </Box>
            <Box className="container" sx={{
                marginTop: { xs: "20px", lg: 0 },
                padding: "0px 20px"
            }}>
                <ProjectsSlider />
                {/* <Box className="row">
                    <Box className="no-mobile" sx={{ paddingLeft: "1vw" }}>
                        <CenteredSlides />
                    </Box>
                    <Box className="mobile-only" sx={{ paddingLeft: "1vw" }}>
                        <MobileCenteredSlides />
                    </Box>
                </Box> */}
            </Box>
        </section >
    )
}

const ProjectsSlider = () => {
    const [heroImage, setHeroImage] = useState(undefined);
    const [heroClasses, setHeroClasses] = useState(undefined);
    const [heroInfo, setHeroInfo] = useState({
        top: `0px`,
        left: `0px`,
        width: 0,
        height: 0,
    });
    const [heroTop, setHeroTop] = useState(0);
    const [heroLeft, setHeroLeft] = useState(0);
    const navigate = useNavigate()
    const settings = {
        // arrows: true,
        dots: true,
        slidesToShow: 5,
        slidesToScroll: 5,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    arrows: true,

                }
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    arrows: true,

                }
            }
        ],
    }

    return (
        <>
            <div css={css`
                        position: fixed;
                        transform:scale(0);
                        /* width:100px;
                        height:100px; */
                        top:0px;
                        left:0px;
                        aspect-ratio: 4/4;
                        background-size: cover;
                        background-position: center;
                        background-color: red;
                        z-index:999999;
                        transition: transform 500ms,top 100ms,left 200ms;
                        transform-origin: center center;
                        /* transform-origin: top left; */
                        &.scale-1{
                            transform: scale(1);
                        }
                        &.scale-big{
                            aspect-ratio: 16/9;
                            transform: scale(5);
                        }
                        &.expand{
                            /* width:100vw;
                            height:100vh; */
                            /* position: fixed; */
                            aspect-ratio: unset;
                        }
                    `}
                className={heroClasses}
                style={{
                    backgroundImage: BG_URL(PUBLIC_URL(heroImage ?? '')),
                    ...heroInfo,
                }}>
            </div>

            <Slider {...settings}>
                {PROJECTS.map((project, index) => {
                    return <Box key={index} className="itemWrapper text-center" sx={{
                        mt: { xs: "10px", md: "30px" },
                        p: 2,
                    }}>
                        <Box sx={{
                            background: 'white',
                            borderRadius: "20px",
                            width: "100%",
                            aspectRatio: "4/4",
                            backgroundImage: BG_URL(project.thumbnail),
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            cursor: 'pointer',
                            position: 'relative'
                        }} onClick={(e) => {
                            console.log(e.target);
                            let el = e.target;
                            var elDistanceToTop = window.scrollY + el.getBoundingClientRect().top
                            setHeroTop(el.getBoundingClientRect().top);
                            setHeroLeft(el.getBoundingClientRect().left);
                            let info = {
                                top: el.getBoundingClientRect().top + 'px',
                                left: el.getBoundingClientRect().left,
                                width: el.getBoundingClientRect().width,
                                height: el.getBoundingClientRect().width * (9.0 / 16),
                            }
                            setHeroInfo(info);
                            setHeroImage(project.thumbnail);
                            setTimeout(() => {
                                setHeroClasses('scale-1');
                            }, 100);
                            setTimeout(() => {
                                info.top = ((window.screen.availHeight / 2) - (info.height / 2)) + 'px';
                                // info.top = `0px`;
                                info.left = ((window.screen.availWidth / 2) - (info.width / 2)) + 'px';
                                setHeroInfo({ ...info });
                                setTimeout(() => {
                                    info.top = info.height * 2.0;
                                    setHeroInfo({ ...info });
                                    // setHeroClasses('scale-big');
                                    setTimeout(() => {
                                        setHeroClasses('scale-big');
                                        setTimeout(() => {
                                            setHeroImage(undefined);
                                            window.scrollTo({
                                                top: 0,
                                                left: 0,
                                                behavior: 'instant',
                                            });
                                            navigate(project.slug);
                                        }, 200);
                                    }, 100);
                                    // info.top = 0;
                                    // info.left = 0;
                                    // setHeroClasses('scale-big');
                                    // setHeroInfo({ ...info });
                                }, 600);
                            }, 100);
                            // setTimeout(() => {
                            //     setHeroTop(0);
                            //     setHeroLeft(0);
                            // }, 400);
                            console.log(`clicked!`);
                            // setTimeout(() => {
                            //     setIsClicked(undefined);
                            // }, 5000);
                            // setTimeout(() => {
                            //     setIsClicked(undefined);
                            //     navigate(project.slug);
                            // }, 1000);
                        }}>
                        </Box>

                        <div className="project-text" style={{ color: "white", }}>
                            {project.slug.replaceAll("-", " ").replace("/", "").toUpperCase()}
                        </div>
                    </Box>
                })}
            </Slider>
        </>
    )
};
