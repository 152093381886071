/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect, useRef, useState } from 'react';
import { BG_URL, PUBLIC_URL } from '../../Utils/utils';
import './Russel.css';
import { useNavigate } from 'react-router-dom';
export default function Russel({ }) {
    const [show, setShow] = useState(false);
    window.setRussel = setShow;
    if (!show)
        return undefined;
    return (
        <>
            <div id={"Russel"}>
                <RusselDesktop />
                <RusselMobile />
            </div>
        </>
    )
}
const COME_IN_DELAY = 0.5 * 1000;
const APPEAR_DELAY = 3 * 1000;
function RusselDesktop({ }) {
    const [showBanner, setShowBanner] = useState(false);
    const [comeIn, setComein] = useState(false);
    const timeOuts = useRef([]);
    const mySection = useRef();
    useEffect(() => {
        const observer = new IntersectionObserver((entries, observer) => {
            const entry = entries[0];
            // console.log('entry', entry);
            // console.log('russel.isVisible=>', entry.isIntersecting);
            if (!entry.isIntersecting) {
                setShowBanner(false);
                setComein(false);
                for (var i = 0; i < timeOuts.current.length; i++) {
                    clearTimeout(timeOuts.current[i]);
                }
                timeOuts.current = [];
                return;
            }
            let t1 = setTimeout(() => {
                // console.log(`:)) toggle banner`);
                setShowBanner(true);
            }, APPEAR_DELAY);
            let t2 = setTimeout(() => {
                setComein(true);
            }, COME_IN_DELAY);
            timeOuts.current.push(t1);
            timeOuts.current.push(t2);
        });
        observer.observe(mySection.current);
    }, [])
    return (
        <div className='no-mobile' css={css`scroll-snap-align:center;`}>
            <section className='russel-section-desktop ' ref={mySection} css={css`
            background-image: ${BG_URL(PUBLIC_URL('assets/russelbg.png'))};
            background-size: cover;
            height: 100vh;
            min-height: 100vh;
        `}>
                <div className='container-fluid'>
                    <div css={css`
                    display: flex;
                    align-items: center;
                    align-content: center;
                    padding:0px 100px;
                `}>
                        <div>
                            <img src={PUBLIC_URL('assets/russel/banner.png')} className={`banner scalable ${showBanner ? '' : 'hidden'}`}
                            />
                            <div css={css`display:flex;align-items:center;flex-direction:column;`}>
                                <div className={`scalable ${showBanner ? '' : 'hidden'}`} css={css`
                            position:relative;cursor: pointer;
                            transition: all 100ms;
                            &:hover{
                                margin-right: 50px;
                            }
                            `}>
                                    <img src={PUBLIC_URL('assets/russel/button.png')} css={css`
                                    width:323px;
                                `} />
                                    <div css={css`position:absolute;top:13px;width:323px;text-align:center;font-size:28px;font-weight:bold;`}>Proceed to website</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className='row'>
                    <div className='col-md-4'>
                        <img src={PUBLIC_URL('assets/russel/banner.png')} css={css`
                        width: 100%;
                    `} />
                    </div>
                </div> */}
                </div>
                <div className={`character ${comeIn ? 'comeIn' : ''}`}>
                    <img src={PUBLIC_URL('assets/russel/character.png')} />
                </div>
            </section>
        </div>
    )
}
function RusselMobile() {
    const [showBanner, setShowBanner] = useState(false);
    const [comeIn, setComein] = useState(false);
    const timeOuts = useRef([]);
    const mySection = useRef();
    const navigate = useNavigate()
    useEffect(() => {
        const observer = new IntersectionObserver((entries, observer) => {
            const entry = entries[0];
            // console.log('entry', entry);
            // console.log('russel.isVisible=>', entry.isIntersecting);
            if (!entry.isIntersecting) {
                setShowBanner(false);
                setComein(false);
                for (var i = 0; i < timeOuts.current.length; i++) {
                    clearTimeout(timeOuts.current[i]);
                }
                timeOuts.current = [];
                return;
            }
            let t1 = setTimeout(() => {
                // console.log(`:)) toggle banner`);
                setShowBanner(true);
            }, APPEAR_DELAY);
            let t2 = setTimeout(() => {
                setComein(true);
            }, COME_IN_DELAY);
            timeOuts.current.push(t1);
            timeOuts.current.push(t2);
        });
        observer.observe(mySection.current);
    }, [])
    return (
        <section className='russel-section-mobile mobile-only' ref={mySection} css={css`
            background-image: ${BG_URL(PUBLIC_URL('assets/russel/bg.png'))};
            height: 100vh;
            scroll-snap-align:center;
            z-index: 2;
        `}>
            <div className='container'>
                <div>
                    <img src={PUBLIC_URL('assets/russel/banner.png')} className={`banner scalable ${showBanner ? '' : 'hidden'}`}
                    />
                    <div css={css`display:flex;align-items:center;flex-direction:column;`} >
                        <a href='https://russelltheredpanda.com/' target='_blank'>
                            <div className={`scalable ${showBanner ? '' : 'hidden'}`} css={css`
                            position:relative;cursor: pointer;
                            transition: all 100ms;
                            `}>
                                <img src={PUBLIC_URL('assets/russel/button.png')} css={css`
                                    width:250px;
                                `} />
                                <div css={css`position:absolute;top:10px;width:250px;text-align:center;font-size:24px;font-weight:bold;text-decoration:none;color:black;`}>Proceed to website</div>
                            </div>
                        </a>
                    </div>
                </div>
                <div className={`character ${comeIn ? 'comeIn' : ''}`}>
                    <img src={PUBLIC_URL('assets/russel/character.png')} css={css`margin-top:30vh;
                    @media (max-height:750px){margin-top:25vh;}`} />
                </div>
            </div>
        </section>
    );
}