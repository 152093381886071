/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Box, Typography, useTheme } from '@mui/material'
import { BG_URL, PUBLIC_URL } from '../../Utils/utils'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Lottie from 'react-lottie';
import langar from '../../lotties/langar.json';
import animationData from '../../lotties/bubbles.json';
import AnimateBox from '../../Components/Animations/AnimateBox';

export default function AboutDelzaar({ }) {
    const theme = useTheme()
    const bubblesOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid meet'
        }
    };
    const langarOptions = {
        loop: true,
        autoplay: true,
        animationData: langar,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid meet'
        }
    };
    return (
        <Box id="aboutDelzaar" css={css`height:100vh;position:relative;scroll-snap-align:center;
        @media (max-width:600px) {height:100vh;}`}>
            <Box sx={{
                backgroundImage: BG_URL(PUBLIC_URL("/assets/about-us/Water-landing.png")),
                backgroundPosition: "center",
                // backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                position: "absolute",
                width: "100%",
                height: "100vh",
                paddingTop: "-100px"
            }} css={css`
            @media (min-width:1920px) {background-size:cover;}
            @media (max-width:1920px) {background-size:none;}
            @media (max-width:600px) {height:105vh;top:-4vh;}`}>
                <Box className="container">
                    {/* typoBox */}
                    <Box sx={{ color: "white", paddingTop: { xs: "15vh", md: "20vh" }, mx: "20px", position: "relative", zIndex: 10 }}>

                        <AnimateBox>
                            <Typography component="div" css={css`font-size:24px;line-height:10px;font-weight:300;
                            @media (max-width:600px) {font-size:18px;}`}>About</Typography>
                            <Typography component="div" css={css`font-size:32px;font-weight:700;
                            @media (max-width:600px) {font-size:22px;}`}>Delzaar Studio</Typography>

                            <Typography css={css`margin-top:30px;font-size:24px;text-align:justify;font-weight:200;
                            @media (max-width:600px) {font-size:16px;}`}>
                                Welcome to Delzaar, the gaming haven where passion and creativity collide!
                                <br></br>
                                Our journey began in Johannesburg, South Africa in 2019 when a group of dedicated gamers came together to form an Indie studio.
                                <br></br>
                                <br></br>
                            </Typography>

                            <Typography css={css`margin-top:30px;font-size:24px;text-align:justify;font-weight:200;margin-top:10px;
                            @media (max-width:600px) {font-size:16px;}`}>
                                Since then, we have been wholeheartedly committed to crafting exceptional games that overflow with love and innovation. With a deep appreciation for teamwork, we strive to contribute to the industry by creating captivating and imaginative experiences for fellow gamers. Join us on this thrilling adventure as we continue to fill the gaming world with boundless creativity and endless joy!                            </Typography>
                        </AnimateBox>


                    </Box>

                    {/* assets */}
                    <Box>
                        <img className='no-mobile' src={PUBLIC_URL("/assets/blueFishes.svg")} style={{
                            position: 'absolute',
                            top: "10vh",
                            right: "10vw",
                            width: "10%"
                        }} />
                        <img className='mobile-only' src={PUBLIC_URL("/assets/blueFishes.svg")} style={{
                            position: 'absolute',
                            top: "10vh",
                            right: "5vw",
                            width: "30%"
                        }} />

                        <div css={css`position:relative;`}>
                            <img className='no-mobile' src={PUBLIC_URL("/assets/about-us/3-fish.png")} style={{
                                position: 'absolute',
                                bottom: "-20vh",
                                left: "0px",
                                width: "12%"
                            }} />
                            <img className='mobile-only' src={PUBLIC_URL("/assets/about-us/3-fish.png")} style={{
                                position: 'absolute',
                                bottom: "-20vh",
                                left: "5vw",
                                width: "30%"
                            }} />

                            <div className='no-mobile' style={{ position: "absolute", bottom: "-20vh", left: "10px", }}>
                                <Lottie
                                    options={bubblesOptions}
                                    height={150}
                                    width={150}
                                    ariaRole=''
                                    isClickToPauseDisabled={true}
                                />
                            </div>
                            <div className='mobile-only' style={{ position: "absolute", bottom: "-20vh", left: "10%", }}>
                                <Lottie
                                    options={bubblesOptions}
                                    height={100}
                                    width={100}
                                    ariaRole=''
                                    isClickToPauseDisabled={true}
                                />
                            </div>
                        </div>


                        <div className='no-mobile' style={{
                            position: 'absolute',
                            bottom: "0vh",
                            right: "15vw",
                            width: "15%",
                            zIndex: 0
                        }}>
                            <div css={css`position:relative;`}>
                                <div css={css`position:absolute;bottom:40px;right:0;z-index:2;`}>
                                    <Lottie
                                        options={langarOptions}
                                        height={250}
                                        width={250}
                                        ariaRole=''
                                        isClickToPauseDisabled={true}
                                    />
                                </div>
                                <div css={css`position:absolute;bottom:0;right:-2vw;z-index:1;`}>
                                    <Lottie
                                        options={bubblesOptions}
                                        height={200}
                                        width={200}
                                        ariaRole=''
                                        isClickToPauseDisabled={true}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='mobile-only' src={PUBLIC_URL("/assets/about-us/anchor.png")} style={{
                            position: 'absolute',
                            bottom: "10vh",
                            right: "5vw",
                            width: "50%",
                            zIndex: 0
                        }}>
                            <div css={css`position:relative;`}>
                                <div css={css`position:absolute;bottom:0px;right:0;z-index:1;`}>
                                    <Lottie
                                        options={langarOptions}
                                        height={200}
                                        width={200}
                                    />
                                </div>
                                <div css={css`position:absolute;bottom:0;right:0;z-index:2;`}>
                                    <Lottie
                                        options={bubblesOptions}
                                        height={200}
                                        width={200}
                                    />
                                </div>
                            </div>
                        </div>

                    </Box>
                </Box>
            </Box>
        </Box >
    )
}
