import { Box, Typography, useTheme } from '@mui/material'
import React from 'react'
import CenteredText from '../../Common/CenteredText'

export default function HowItStarted({ content, setFullScreenSrc }) {
  const theme = useTheme()
  return (
    <Box mt={10} className="container" sx={{ position: "relative", zIndex: 4 }}>
      <Typography textAlign={"center"} sx={{ fontSize: { xs: "25px", md: theme.palette.xxl } }}>How It All Started</Typography>
      <Typography my={3} sx={{ fontSize: { xs: "20px", lg: theme.palette.m } }}>{content.text}</Typography>
      <div style={{ cursor: "pointer" }} onClick={() => { setFullScreenSrc(content.image) }}>
        <img style={{ width: "100%", aspectRatio: "16/9" }} src={content.image} />
      </div>
    </Box>
  )
}
