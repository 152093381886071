import { ambassadorOfLove } from "./ambassador-of-love";
import { beautyCold } from "./beauty-cold";
import { brother } from "./brother";
import { cryingHeart } from "./crying-heart";
import { farewell } from "./farewell";
import { imc } from "./imc";
import { sunset } from "./sunset";
import { tideTurn } from "./tide-turn";
import { vrHaj } from "./vr-haj";
import { vrKarbala } from "./vr-karbala";

export const PROJECTS = [
    beautyCold,
    sunset,
    cryingHeart,
    ambassadorOfLove,
    brother,
    farewell,
    tideTurn,
    imc,
    vrHaj,
    vrKarbala,
];
export const FIND_PROJECT = (slug) => PROJECTS.find(t => t.slug == slug);