/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Box, Drawer, IconButton, Typography, useTheme } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import React, { useEffect, useState } from 'react';
import { BG_URL, PUBLIC_URL } from '../../Utils/utils';
import PetsIcon from '@mui/icons-material/Pets';
import "./Navbar.css"
import { FullpageContext } from '@ap.cx/react-fullpage';
const LOGO = "/assets/logo-typo.svg"

export default function Navbar({
    LandingRef,
    ProjectsRef,
    MissionRef,
    RusselRef,
    ContactUsRef,
    scrollTo,
}) {
    const theme = useTheme();
    const location = useLocation()

    const [fontColor, setFontColor] = useState("white")
    const navigate = useNavigate();
    const [state, setState] = React.useState({
        right: false,
    });
    const [isMouseHover, setIsMouseHover] = useState(false)

    const [hasScrolled, setHasScrolled] = useState(false);
    const handleScroll = () => {
        const position = window.scrollY;
        if (position > 800) {
            setHasScrolled(true)
        }
        else setHasScrolled(false)
    };

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    useEffect(() => {
        var navElement = document.getElementById("AppBar");
        navElement.style.opacity = "1"
        window.addEventListener("scroll", changeCss, false)
        window.addEventListener("scroll", changeCss, true)
        navElement.addEventListener("mouseover", visible, false)
        navElement.addEventListener("mouseover", visible, true)
        navElement.addEventListener("mouseleave", unvisible, true)
        navElement.addEventListener("mouseleave", unvisible, false)

        var navElementMobile = document.getElementById("mobileAppBar");
        window.addEventListener("scroll", changeCss, false)
        window.addEventListener("scroll", changeCss, true)
    }, [])
    function visible() {
        setIsMouseHover(true)
        var navElement = document.getElementById("AppBar");
        if (navElement != null && navElement !== undefined) {
            navElement.style.opacity = "1"
            navElement.style.background = "rgba(0,0,0,0.6)"
        }
    }
    function unvisible() {
        var navElement = document.getElementById("AppBar");
        var bodyRect = document.body.getBoundingClientRect()
        if (isMouseHover) return
        if (navElement != null && navElement !== undefined) {
            bodyRect.top > -100 ? navElement.style.opacity = "1" : navElement.style.opacity = "0"
            navElement.style.background = "transparent"
            this.scrollY < 100 ? navElement.style.opacity = "1" : navElement.style.opacity = "0"
        }
    }
    function changeCss() {
        handleScroll()
        var navElement = document.getElementById("AppBar");
        if (navElement) {
            if (isMouseHover) {
                navElement.style.opacity = "1"
                return
            }
            if (window.location.pathname !== "/") {
                navElement.style.background = "none"
                navElement.style.opacity = "0"
            }
            if (window.location.pathname == "/") {
                console.log("here")
                window.scrollY < 100 ? navElement.style.background = "none" : navElement.style.background = "rgba(0,0,0,0.6)";
                window.scrollY < 100 ? navElement.style.opacity = "1" : navElement.style.opacity = "0";
            }
        }
        var navElementMobile = document.getElementById("mobileAppBar");
        if (navElementMobile) {
            this.scrollY < 100 ? navElementMobile.style.background = "none" : navElementMobile.style.background = "rgba(0,0,0,0.6)";
        }
    }
    return (
        <>
            <Box className='no-mobile' id="AppBar" sx={{
                height: "100px",
                position: 'fixed',
                top: 0,
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: 10,
                padding: "0px 2%",
                margin: "0 auto",
                width: "100%",
                transition: "500ms",
                zIndex: 100
            }}>
                <Box className="container">
                    <Box sx={styles.contentWrapper}>
                        <Box sx={styles.logoWrapper} onClick={() => { navigate('/'); }} css={css`margin-left:-15vw;`}>
                            {hasScrolled ?
                                <Typography sx={{ paddingLeft: "20px", fontWeight: 'bolder', fontSize: "30px", transition: "1000ms", width: "fit-content" }} color="white">DELZAAR STUDIO</Typography>
                                :
                                <img src={PUBLIC_URL(LOGO)} style={{
                                    width: "80px",
                                    transition: "opacity 1s linear",
                                    position: "absolute",
                                    left: "50%",
                                    top: "50%",
                                    transform: "translate(-50%,-50%)",
                                    transition: "1000ms"
                                }} />
                            }
                        </Box>
                        <Box sx={{ flex: { sm: 2, md: 6 } }}></Box>
                        <Box sx={styles.linksWrapper}>
                            <NavbarLink text={"Home"} fontColor={fontColor} LinkRef={LandingRef} scrollTo={scrollTo} />
                            <NavbarLink text={"Projects"} fontColor={fontColor} LinkRef={ProjectsRef} scrollTo={scrollTo} />
                            <NavbarLink text={"Russel"} fontColor={fontColor} logo={<PetsIcon sx={{ color: "white", ml: 0.5 }} />} LinkRef={RusselRef} scrollTo={scrollTo} isRussel={true} />
                            <NavbarLink text={"About"} fontColor={fontColor} LinkRef={MissionRef} scrollTo={scrollTo} />
                            <NavbarLink text={"Contact"} fontColor={fontColor} LinkRef={ContactUsRef} scrollTo={scrollTo} />
                        </Box>
                    </Box>
                </Box>
            </Box >

            <Box className="mobile-only"
                sx={{
                    height: "100px",
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: "space-between",
                    position: 'fixed',
                    top: -20,
                    zIndex: 10,
                    width: "100%",
                    transition: "500ms",
                    zIndex: 100,
                }}>
                <Box id="mobileAppBar" sx={styles.mobileContentWrapper}>
                    <Box sx={{ position: "relative" }} onClick={() => { navigate('/'); }}>
                        {/* {hasScrolled ?
                            <Typography sx={{ fontWeight: 'bolder', fontSize: "30px", transition: "1000ms" }} color="white">DELZAAR STUDIO</Typography>
                            : */}
                        <Box sx={styles.logoWrapper} onClick={() => { navigate('/'); }}>
                            <img src={PUBLIC_URL(LOGO)} style={{
                                width: "80px",
                                transition: "opacity 1s linear",
                                position: "absolute",
                                left: "0",
                                top: "40%",
                                transform: "translateY(-50%)",
                            }} />
                        </Box>
                        {/* } */}
                    </Box>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={toggleDrawer('right', true)}
                    >
                        <MenuIcon sx={{ fontSize: 40 }} />
                    </IconButton>
                    <Drawer
                        anchor={'right'}
                        open={state['right']}
                        onClose={toggleDrawer('right', false)}
                        onOpen={toggleDrawer('right', true)}
                    >
                        <Box style={{ width: '80vw' }}>
                            <Box sx={styles.mobileLinksWrapper}>
                                <NavbarMobileLink toggleDrawer={setState} text={"Home"} LinkRef={LandingRef} scrollTo={scrollTo} />
                                <NavbarMobileLink toggleDrawer={setState} text={"Projects"} LinkRef={ProjectsRef} scrollTo={scrollTo} />
                                <NavbarMobileLink toggleDrawer={setState} text={"Russel"} LinkRef={RusselRef} scrollTo={scrollTo} isRussel={true} />
                                <NavbarMobileLink toggleDrawer={setState} text={"About"} LinkRef={MissionRef} scrollTo={scrollTo} />
                                <NavbarMobileLink toggleDrawer={setState} text={"Contact"} LinkRef={ContactUsRef} scrollTo={scrollTo} />
                            </Box>
                        </Box>
                    </Drawer>
                </Box>
            </Box>
        </>
    )
}

const NavbarLink = ({ fontColor, text, logo, to = '/', LinkRef, scrollTo, isRussel = false }) => {
    const location = useLocation();
    const navigate = useNavigate();
    return (
        <Box sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }}>
            <Box onClick={() => {
                if (isRussel)
                    window.setRussel(true);
                setTimeout(() => {
                    scrollTo(LinkRef)
                }, 100);
                // let ref = document.getElementById(text)
                // if (location.pathname != '/') {
                //     navigate(to);
                //     return;
                // }
                // if (text == "Home") {
                //     window.scrollTo(0, 0);
                // }
                // else {
                //     window.scrollTo({
                //         top: ref.offsetTop
                //     })
                // };
            }}
                sx={{
                    borderBottom: `none`,
                    transition: "all 300ms",
                    "&:hover": {
                        borderBottom: `4px solid ${fontColor}`,
                        transition: "300ms",
                        cursor: "pointer"
                    }
                }}>
                {
                    logo ?
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                            <Typography textAlign={"center"} component={"div"} color={fontColor}>{text}</Typography>
                            {logo}
                        </Box>
                        :
                        <Typography textAlign={"center"} component={"div"} color={fontColor}>{text}</Typography>
                }
                <Typography textAlign={"center"} component={"div"} color={fontColor} sx={{ lineHeight: "8px", fontSize: "20px", fontWeight: "bold" }}>.</Typography>
            </Box>
        </Box >
    )
}
const NavbarMobileLink = ({ text, toggleDrawer, LinkRef, scrollTo, isRussel = false }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const mobileCSSThing = css`
        display:block;
        padding:20px 0;
        text-align:center;
        font-weight:bolder;
        border-bottom:1px solid #e0e1e2;
        width:100%;
        :hover{
            background-color: #d0d1d2;
        }
    `;
    return (
        <Box>
            <Box onClick={() => {
                if (isRussel)
                    window.setRussel(true);
                setTimeout(() => {
                    scrollTo(LinkRef)
                }, 100);
                toggleDrawer({ right: false });
                if (location.pathname != '/') {
                    navigate("/")
                }
                // let ref = document.getElementById(text)
                // if (text == "Home") {
                //     window.scrollTo(0, 0);
                // }
                // else {
                //     window.scrollTo({
                //         top: ref.offsetTop
                //     })
                //     console.log(ref)
                // };
                // if (!ref) return
                // if (!ref.offsetTop) return
                // window.scrollTo({
                //     top: ref.offsetTop
                // });

            }
            }
                css={mobileCSSThing}>
                <Typography sx={{ fontWeight: 'bolder' }} color="#333333">{text}</Typography>
            </Box>
        </Box>
    )
}


const styles = {
    contentWrapper: {
        display: "flex",
        width: "100%",
        height: "100px",
        alignItems: "center"
    },
    mobileContentWrapper: {
        display: "flex",
        width: "100%",
        height: "100px",
        justifyContent: "space-between",
        flexDirection: "row",
        padding: "0px 20px",
        alignItems: 'center'
    },
    logoWrapper: {
        cursor: 'pointer',
        position: "relative",
        flex: 4
    },
    linksWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        flex: 6
    },
    logo: {
        width: "50px",
        height: "50px",
        backgroundColor: "#2196f3",
        m: 1,
        borderRadius: "10px"
    },
    navLink: {
        width: "100px",
        fontWeight: 'bolder',
        textAlign: 'center'
    },
    // mobile
    mobileLogoWrapper: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        p: 1,
    },
    mobileLinksWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
}