import { PUBLIC_URL } from "../../../Utils/utils";

export const sunset = {
    slug: 'sunset',
    thumbnail: PUBLIC_URL('/assets/projects/sunset.jpg'),
    bg: PUBLIC_URL("/assets/projectSingles/backgrounds/karbala.png"),
    playIcon: PUBLIC_URL("/assets/projectSingles/play-button.png"),
    video: {
        video: "https://youtu.be/ETuEgLSMkNM",
        typo: PUBLIC_URL("/assets/projectSingles/typographys/sunset.png"),
        title: "Short Animation",
        date: "2018",
        particle: undefined

    },
    start: {
        text: "Sunset is a short animation about the historical events of the “Battle of Karbala” which was fought on 10 October 680, illustrating the tragedy of Huseyn ibn Ali martydom.",
        image: "/assets/projectSingles/sunset/banner-2.jpg",
    },
    process: {
        bgParticle: undefined,
        particles: undefined,
        image: "/assets/projectSingles/sunset/banner-3.jpg",
        texts: [
            "First we design some concepts for elements and assets that we wanted to be in the scene such as trees animals and etc.",
            "Then we created 3D models based on concepts that were approved to be to our liking, futhermore we pbr textured the models and made them ready to be set in the scene.",
            "We used low poly models with pbr textures and compatibale meshs., how ever it was a personal project yet we tried our best to use our abilities to the maximum."
        ]
    },
    design: {
        bgParticle: undefined,
        text: "In “Sunset” it was challenging to make the fire look more like what we had in mind than what we already did because the lack of Niagra particle system and Lumen back then however we pulled the best out of lighting and shadows using cascade and other solutions.",
        image: "/assets/projectSingles/sunset/banner-4.jpg",
    },
    galleryBgParticle: undefined,
    gallery: [
        "https://youtu.be/lL7m_hEYtxI",
        "https://youtu.be/0CETMOPlOe4",
        "https://youtu.be/BmkA2FZ54qg",
        "https://youtu.be/UabW3_uVEDw"

    ],
    images: [
        PUBLIC_URL("/assets/projectSingles/sunset/banner-3.jpg"),
        PUBLIC_URL("/assets/projectSingles/sunset/banner-1.jpg"),
        PUBLIC_URL("/assets/projectSingles/sunset/banner-2.jpg"),
    ],
}