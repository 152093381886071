/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Box, Button, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { BG_URL, PUBLIC_URL } from '../../Utils/utils'
import boatAnimation from '../../lotties/boat.json';
import lightHouse from '../../lotties/lighthouse.json';
import boatCloud from '../../lotties/boatCloud.json';
import Lottie from 'react-lottie';
import AnimateBox from '../../Components/Animations/AnimateBox';

export default function OurMission({ isAboutUsOpen, setIsAboutUsOpen, refrence, AboutUsRef, scrollTo}) {
  const theme = useTheme()
  const boatOptions = {
    loop: true,
    autoplay: true,
    animationData: boatAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid meet'
    }
  }
  const boatCloudOptions = {
    loop: true,
    autoplay: true,
    animationData: boatCloud,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid meet'
    }
  }
  const lightHouseOptions = {
    loop: true,
    autoplay: true,
    animationData: lightHouse,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid meet'
    }
  }
  const [x, setX] = useState("-20vw");
  useEffect(() => {
    setX("105vw");
    const interval = setInterval(() => {
      setX(prev => prev == "105vw" ? "-20vw" : "105vw");
    }, 80000);
    return () => {
      clearInterval(interval);
    }
  }, [])

  return (
    <Box id="About" sx={{
      scrollSnapAlign: "center",
      height: "100vh",
      backgroundImage: BG_URL(PUBLIC_URL('/assets/about-us/about-bg.png')),
      backgroundPosition: { md: "center" },
      backgroundSize: "cover",
      position: "relative",
      overflow: "hidden",
      zIndex: 5,
    }}>
      <Box className="container">
        {/* typoBox */}
        <Box sx={{ paddingTop: { xs: "5vh", md: "10vh" }, paddingBottom: 0, mx: "20px", position: "relative", zIndex: 2, textAlign: "justify" }}>

          <AnimateBox component={"mission"}>
            <Typography component="div" css={css`font-size:24px;line-height:10px;
            @media (max-width:600px) {font-size:18px;}`}>About</Typography>
            <Typography component="div" css={css`font-size:36px;font-weight:800;
            @media (max-width:600px) {font-size:22px;}`}>Our Mission</Typography>
          </AnimateBox>

          <AnimateBox component={"mission"}>
            <Typography css={css`margin-top:20px;font-size:24px;font-weight:300;line-height:30px;
            @media (max-width:600px) {font-size:16px;line-height:22px;}`}>
              We are wonderers of imagination, we packed our bags of experience and started our adventure to the open world of game design with a
              <b style={{ fontWeight: "bold" }}>&nbsp;Main Quest</b>;
              We dream of a world with more <b style={{ fontWeight: "bold" }}>creative games</b>!
              <br></br>
            </Typography>
          </AnimateBox>

          <AnimateBox component={"mission"}>
            <Typography css={css`margin-top:20px;font-size:24px;font-weight:300;line-height:30px;margin-top:0;
            @media (max-width:600px) {font-size:16px;line-height:22px;}`}>
              Buckle up brave adventurer your journey starts from here...
            </Typography>
          </AnimateBox>
          <Box css={css`display:flex;justify-content:center;margin-top:20vh; 
          @media (max-width:600px) {margin-top:30vh;}`} onClick={() => scrollTo(AboutUsRef)}>
            <AnimateBox component={"mission"} >
              <Button variant='contained' css={css`background: #ff6666;border-radius:12px;padding:2px 20px;color:black;text-transform:none;font-size:20px;z-index:999;
              @media (max-width:600px) {font-size:12px;}`} >
                Read More...
              </Button>
            </AnimateBox>
          </Box>
        </Box>
      </Box>
      {/* assetsBox */}
      <Box>

        {/* clouds */}
        <div className='no-mobile'>
          <Lottie
            options={boatCloudOptions}
            height={600}
            width={600}
            style={{
              position: 'absolute', bottom: "-5vh",
              right: "45vw",
              top: "15vh",
              zIndex: 0
            }}
          />
        </div>
        <div className='mobile-only'>
          <Lottie
            options={boatCloudOptions}
            height={300}
            width={300}
            style={{
              position: 'absolute', bottom: "-5vh",
              right: "15vw",
              top: "30vh",
              zIndex: 0
            }}

          />
        </div>
        {/* boat */}
        <div className='no-mobile'
        >
          <Lottie
            options={boatOptions}
            height={350}
            width={350}
            style={{
              position: 'absolute', bottom: "-5vh",
              right: x,
              transition: x == "-20vw" ? "0ms" : "100000ms",
              zIndex: 2
            }}
          />
        </div>
        <div className='mobile-only'>
          <Lottie
            options={boatOptions}
            height={150}
            width={150}
            style={{
              position: 'absolute', bottom: "3vh", right: x,
              transition: x == "-20vw" ? "0ms" : "100000ms",
              zIndex: 2
            }}
          />
        </div>

        {/* light house */}
        <div className='no-mobile'>
          <Lottie
            options={lightHouseOptions}
            height={600}
            width={600}
            ariaRole=''
            isClickToPauseDisabled={true}
            style={{
              position: "absolute",
              bottom: "0px",
              right: "5vw",
              zIndex: 1
            }}
          />
          <Lottie
            options={lightHouseOptions}
            height={600}
            width={600}
            ariaRole=''
            isClickToPauseDisabled={true}
            style={{
              position: "absolute",
              bottom: "-380px",
              right: "5vw",
              zIndex: 0,
              transform: "rotateX(180deg)",
              opacity: "50%",
            }}
          />
        </div>
        <div className='mobile-only'>
          <Lottie
            options={lightHouseOptions}
            height={300}
            width={300}
            style={{
              position: "absolute",
              bottom: "60px",
              right: "5vw",
              zIndex: 1
            }}
          />
          <Lottie
            options={lightHouseOptions}
            height={300}
            width={300}
            style={{
              position: "absolute",
              bottom: "-130px",
              right: "5vw",
              zIndex: 0,
              transform: "rotateX(180deg)",
              opacity: "50%",
            }}
          />
        </div>

        {/* rocks */}
        <img className='no-mobile' src={PUBLIC_URL("/assets/cliff.svg")}
          style={{
            position: "absolute",
            width: "400px",
            bottom: "20px",
            left: 0,
            zIndex: 3
          }} />
        <img className='mobile-only' src={PUBLIC_URL("/assets/cliff.svg")}
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "40%",
            zIndex: 3
          }} />
      </Box>
      {/* <Box sx={{
        position: "absolute",
        left: "50%",
        top: { xs: "50%", lg: "50%" },
        transform: { xs: "translate(-50%,-30%)", md: "translate(-50%,-50%)" },
      }}>
      </Box> */}
    </Box >
  )
}
