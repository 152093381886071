/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { BG_URL, PUBLIC_URL } from '../Utils/utils'
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';

export default function NotFound() {
    const navigate = useNavigate()
    return (
        <Box css={css`width:100vw;height:100vh;`}>
            <section
                style={{ backgroundImage: BG_URL(PUBLIC_URL("/assets/nf.svg")) }}
                css={css`transform: scale(1.005);background-position:center;background-size:cover;width:100%;height:100%;display:flex;flex-direction:row;justify-content:center;align-items:center;`}>
                <Button onClick={() => { navigate('/') }} css={css`background-color:rgba(0,0,0,0.6);color:white;margin-top:300px;font-size:28px;`}
                    sx={{ ":hover": { backgroundColor: "rgba(0,0,0,0.8)", color: "white" } }}
                >
                    GET&nbsp;&nbsp;BACK&nbsp;&nbsp;TO&nbsp;&nbsp;HOMEPAGE
                </Button>
            </section>
        </Box>
    )
}
