import React from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import { isEmptyString } from '../../Utils/utils'

export default function ProjectProcess({ content, setFullScreenSrc }) {
  const theme = useTheme()
  return (
    <Box mt={10} sx={{ position: "relative" }}>
      {!isEmptyString(content.bgParticle) ?
        <img style={{
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 0,
          height: "60%"
        }} src={content.bgParticle} />
        : undefined
      }
      <Box className="container" sx={{ position: "relative", zIndex: 2 }}>
        <Typography textAlign={"center"} sx={{ fontSize: { xs: "25px", md: theme.palette.xxl } }}>Project Process</Typography>


        {!content.particles ?
          <>
            <Typography my={6} sx={{ fontSize: { xs: "20px", lg: theme.palette.m } }}>{content.texts[0]}</Typography>
            <Typography mb={6} sx={{ fontSize: { xs: "20px", lg: theme.palette.m } }}>{content.texts[1]}</Typography>
          </> :
          <Box className="row">
            <Box className='col-9'>
              <Typography my={6} sx={{ fontSize: { xs: "20px", lg: theme.palette.m } }}>{content.texts[0]}</Typography>
              <Typography mb={6} sx={{ fontSize: { xs: "20px", lg: theme.palette.m } }}>{content.texts[1]}</Typography>
            </Box>
            <Box className='col-3'><img style={{ width: "100%" }} src={content.particles[0]} /></Box>
          </Box>
        }

        {!content.particles ?
          <Typography mb={6} mt={1} sx={{ fontSize: { xs: "20px", lg: theme.palette.m } }}>{content.texts[2]}</Typography>
          :
          <Box className="row" sx={{ alignItems: 'center' }}>
            <Box className='col-2'><img style={{ width: "100%" }} src={content.particles[1]} /></Box>
            <Typography className="col-10" mb={6} mt={1} sx={{ fontSize: { xs: "20px", lg: theme.palette.m } }}>{content.texts[2]}</Typography>
          </Box>
        }
        <div style={{ cursor: "pointer" }} onClick={() => { setFullScreenSrc(content.image) }}>
          <img style={{ width: "100%", aspectRatio: "16/9" }} src={content.image} />
        </div>
      </Box>
    </Box>
  )
}
