/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Box, Typography, useTheme } from '@mui/material'
import React, { useEffect } from 'react'
import { BG_URL, PUBLIC_URL } from '../../Utils/utils'
import Swiper from 'react-id-swiper';
import 'swiper/swiper.css'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import AnimateBox from '../../Components/Animations/AnimateBox';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import animationData from '../../lotties/bubbles.json';
import Lottie from 'react-lottie';

export default function AboutOurTeam({ }) {
    const theme = useTheme()
    const bubblesOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid meet'
        }
    };
    return (
        <Box id="Team" sx={{
            scrollSnapAlign: "center",
            backgroundImage: BG_URL(PUBLIC_URL("/assets/Back2.jpg")),
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "100vh",
            paddingTop: 8,
            overflow: "hidden",
            position: "relative",
        }}>
            <Box className="container">
                {/* typo box */}
                <Box position={"relative"} >
                    <Box sx={{
                        position: "absolute",
                        left: "8vw",
                        bottom: "-20vh"
                    }}>
                        <Lottie
                            options={bubblesOptions}
                            height={200}
                            width={200}
                            ariaRole=''
                            isClickToPauseDisabled={true}
                        />
                    </Box>
                    <Box sx={{
                        position: "absolute",
                        right: "-10vw",
                        bottom: "-30vh"
                    }}>
                        <Lottie
                            options={bubblesOptions}
                            height={150}
                            width={150}
                            ariaRole=''
                            isClickToPauseDisabled={true}
                        />
                    </Box>
                    <Box sx={{
                        position: "absolute",
                        right: "7vw",
                        bottom: "-14vh"
                    }}>
                        <Lottie
                            options={bubblesOptions}
                            height={200}
                            width={200}
                            ariaRole=''
                            isClickToPauseDisabled={true}
                        />
                    </Box>
                    <AnimateBox component={"team"}>
                        <Box sx={{ color: "white", mx: "20px", textAlign: "justify" }}>
                            <Typography component="div" css={css`font-size:24px;line-height:10px;font-weight:300;
                            @media (max-width:600px) {font-size:18px;}`}>About</Typography>
                            <Typography component="div" css={css`font-size:34px;font-weight:700;
                            @media (max-width:600px) {font-size:22px;}`}>Our Team</Typography>
                            {/* <Typography component="div" my={5} sx={{ fontSize: theme.palette.m }}>We gathered the most creative specialists we knew to make the best out of it! No matter the cost!</Typography> */}
                            <Typography component="div" my={5} css={css`font-size:22px;line-height:26px;font-weight:200;margin-top:20px;
                            @media (max-width:600px) {font-size:17px;}`}>
                                Get ready to be dazzled by the incredible talent at Delzaar! Our extraordinary team members are the true architects of our studio's success. With unwavering passion, ingenuity, and tireless efforts, they have brought Delzaar to life and propelled us to new heights.
                            </Typography>
                        </Box>
                        <Box css={css`margin-bottom:400px;`}>
                            <AboutSlider />
                        </Box>
                    </AnimateBox>
                </Box>
            </Box>
        </Box>
    )
}

const AboutSlider = () => {
    const settings = {
        arrows: true,
        dots: true,
        slidesToShow: 5,
        slidesToScroll: 5,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false,

                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    arrows: true,

                }
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    arrows: true,

                }
            }
        ],
    }

    return (
        <Slider {...settings}>
            {persons.map((person, index) => {
                return <Box key={index} p={2}>
                    <Box className="team-image" sx={{
                        aspectRatio: "3/4",
                        borderRadius: "10px",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        position: "relative",
                        backgroundImage: BG_URL(PUBLIC_URL(`/assets/avatars/${person}`)),
                    }} />
                </Box>
            })}
        </Slider>

    )
};
const persons = [
    "AEbrahimnejad.png",
    "AKia.png",
    "Gnoori.png",
    "HKarimi.png",
    "HMirhashemi.png",
    "Hnaderl.png",
    "HZia.png",
    "MAtoofi.png",
    "MHcheli.png",
    "MKeshtkar.png",
]