import React, { useEffect, useMemo, useState } from 'react'
import { Box } from '@mui/material'
import VideoSection from './VideoSection'
// import GameDesign from './GameDesign'
// import CharacterDevelopment from './CharacterDevelopment'
import GalleryAndImages from './GalleryAndImages'
import HowItStarted from './HowItStarted'
import ProjectProcess from './ProjectProcess'
import Story from './Story'
import WorldCreation from './WorldCreation'
import SceneDesignAndLighting from './SceneDesignAndLighting'
import LoadingScreen from '../../Components/Loading/LoadingScreen'
import { BG_URL, PUBLIC_URL, isEmptyString } from '../../Utils/utils'
import TechnicalDifficulties from './TechnicalDifficulties'
import Images from './Images'
import { projectHandler } from './ProjectSingleHandler'
import Crowd from './Crowd'
import Tide from './Tide'
import Imc from './Imc'
import Swiper from 'react-id-swiper';
import 'swiper/swiper.css'
import { Helmet } from "react-helmet";
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom'
import { FIND_PROJECT, PROJECTS } from './data/all_projects'
import Footer from '../../Components/Layout/Footer'
import Navbar from '../../Components/Layout/Navbar'
export default function ProjectSingle({ path }) {
    const [header, setHeader] = useState("Delzaar")
    const project = useMemo(() => FIND_PROJECT(path), [path])
    const [fullScreenSrc, setFullScreenSrc] = useState(undefined)
    useEffect(() => {
        let _path = path;
        _path = _path.replaceAll("-", " ")
        setHeader(`Delzaar - ${_path}`)
        return () => { setHeader("Delzaar") }
    }, [])
    const navigate = useNavigate()
    return (
        <>
            <Navbar />
            <Helmet>
                <meta charSet="utf-8" />
                <title>{header}</title>
                <link rel="canonical" href={`http://delzaar.com/${path}`} />
            </Helmet>
            <div onClick={() => { navigate(-1) }}
                style={{
                    position: "fixed",
                    width: "60px",
                    height: "60px",
                    borderRadius: "50%",
                    border: "1px solid lightGrey",
                    zIndex: fullScreenSrc ? -1 : 99999,
                    top: "50px",
                    right: "50px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: "0px 0px 25px 10px rgba(0,0,0,0.5)"
                }}>
                <CloseIcon sx={{ fontSize: "45px", fontWeight: "100", color: "lightGrey" }} />
            </div>
            {
                fullScreenSrc ?
                    <div style={{
                        position: "sticky",
                        width: "100vw",
                        height: "100vh",
                        background: "rgba(0,0,0,0.8)",
                        zIndex: 9999,
                        top: 0,
                        right: 0
                    }}>
                        <div style={{ position: "relative" }}>
                            <img src={fullScreenSrc} style={{
                                width: "100%"
                            }} />
                            <div onClick={() => { setFullScreenSrc(undefined) }}
                                style={{
                                    position: "absolute",
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "50%",
                                    border: "1px solid lightGrey",
                                    zIndex: 99999,
                                    top: 10,
                                    right: 10,
                                    cursor: "pointer",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    boxShadow: "0px 0px 25px 10px rgba(0,0,0,0.5)"
                                }}>
                                <CloseIcon sx={{ color: "lightGrey" }} />
                            </div>
                        </div>
                    </div>
                    : undefined
            }
            {
                project ?
                    path == "tide-turn" ? <Tide project={project} setFullScreenSrc={setFullScreenSrc} /> : path == "imc" ? <Imc project={project} setFullScreenSrc={setFullScreenSrc} /> :
                        <Box sx={{
                            backgroundImage: BG_URL(project.bg),
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            color: "white",
                            width: "100%",
                            p: 0,
                            m: 0
                        }}
                        >
                            <VideoSection content={project.video} thumbnail={project.thumbnail} playIcon={project.playIcon} setFullScreenSrc={setFullScreenSrc} />
                            <HowItStarted content={project.start} setFullScreenSrc={setFullScreenSrc} />
                            <ProjectProcess content={project.process} setFullScreenSrc={setFullScreenSrc} />
                            {isEmptyString(project.story) ? undefined : <Story content={project.story} setFullScreenSrc={setFullScreenSrc} />}
                            {isEmptyString(project.crowd) ? undefined : <Crowd content={project.crowd} setFullScreenSrc={setFullScreenSrc} />}
                            {isEmptyString(project.design) ? undefined : <SceneDesignAndLighting content={project.design} setFullScreenSrc={setFullScreenSrc} />}
                            {isEmptyString(project.difficulties) ? undefined : <TechnicalDifficulties content={project.difficulties} setFullScreenSrc={setFullScreenSrc} />}
                            <Box className="container">
                                {isEmptyString(project.worldCreation) ? undefined : <WorldCreation content={project.worldCreation} setFullScreenSrc={setFullScreenSrc} />}
                            </Box>
                            <GalleryAndImages setFullScreenSrc={setFullScreenSrc} gallery={project.gallery} images={project.images} galleryBgParticle={project.galleryBgParticle} playIcon={project.playIcon} />

                        </Box>
                    :
                    <LoadingScreen />
            }
        </>
    )
}