import { PUBLIC_URL } from "../../../Utils/utils";

export const ambassadorOfLove = {
    slug: 'ambassador-of-love',
    thumbnail: PUBLIC_URL('/assets/projects/Ambassador-thumbnail.jpg'),
    bg: PUBLIC_URL("/assets/projectSingles/backgrounds/ambassador.png"),
    playIcon: PUBLIC_URL("/assets/projectSingles/play-button.png"),
    video: {
        // video: "https://youtu.be/4d4MjVyAWmA",
        video: PUBLIC_URL(`/assets/projectSingles/ambassador-of-love/header-video.mp4`),
        typo: PUBLIC_URL("/assets/projectSingles/typographys/ambassador.png"),
        title: "Short Animation",
        date: "2018",
        // particle: PUBLIC_URL("/assets/projectSingles/sunset/fire-effect-1.png")
    },
    start: {
        text: ' Ambassador of Love is a hack & slash 3D game for PC and Xbox illustrating the tragic story of ashura from the point of view of a middle eastern character named Mehran. Delzaar Studio helped RSK Entertainment Company to create and design its environments and levels, including lighting and shading the scene plus some guidance in the story-telling process.',
        image: "/assets/projectSingles/ambassador-of-love/01.jpg",
    },
    process: {
        bgParticle: undefined,
        particles: undefined,
        image: "/assets/projectSingles/ambassador-of-love/09.png",
        texts: [
            "First we designed some concepts for enviornments such as the household of Mokhtar, City of Kufa in Iraq, Darul 'Imara in Iraq and the interior of all these places plus their assets.",
            "Then we created 3D models based on concepts that were approved to be to our liking, futhermore we pbr textured the models and made them ready to be optimized for the game and have them set in the scene.",
            "We used low poly models with pbr textures and compatibale meshs, to make it ultra compatible for console and pc games."
        ]
    },
    story: {
        bgParticle: undefined,
        particles: undefined,
        image: "/assets/projectSingles/ambassador-of-love/10.png",
        texts: [
            "In “Ambassador of love” we also helped them right the story line for the game and its events",
            "but there were some changes into it on the final outcome yet it delvered the most important message which was the historical events of ashura.",
        ]
    },
    galleryBgParticle: undefined,
    gallery: [
        "https://youtu.be/ZJ1ycP88cuQ",
        "https://youtu.be/b_-XH5apPEI",
        "https://youtu.be/VH9hKApoyDU",
        "https://youtu.be/qIUarbsilxQ",
        "https://youtu.be/mhGQtFfVNII",
        "https://youtu.be/1c1b9-8DCK4"

    ],
    images: [
        PUBLIC_URL("/assets/projectSingles/ambassador-of-love/05.jpg"),
        PUBLIC_URL("/assets/projectSingles/ambassador-of-love/11.png"),
        PUBLIC_URL("/assets/projectSingles/ambassador-of-love/12.png"),
        PUBLIC_URL("/assets/projectSingles/ambassador-of-love/02.jpg"),
        PUBLIC_URL("/assets/projectSingles/ambassador-of-love/03.jpg"),
        PUBLIC_URL("/assets/projectSingles/ambassador-of-love/04.jpg"),
        PUBLIC_URL("/assets/projectSingles/ambassador-of-love/05.jpg"),
        PUBLIC_URL("/assets/projectSingles/ambassador-of-love/07.jpg"),
        PUBLIC_URL("/assets/projectSingles/ambassador-of-love/08.jpg"),
    ],
}