import { useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Home from '../../Pages/Landing/Home';
import Navbar from '../Layout/Navbar';
import './App.css';
import "animate.css/animate.min.css";
import ProjectSingle from '../../Pages/ProjectSingle/ProjectSingle';
import LoadingScreen from '../Loading/LoadingScreen';
import Footer from '../Layout/Footer';
import NotFound from '../../Pages/NotFound';

function App() {
  return (
    <BrowserRouter>
      <LoadingScreen />
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/beauty-cold" element={<ProjectSingle path={'beauty-cold'} />} />
          <Route path="/sunset" element={<ProjectSingle path={'sunset'} />} />
          <Route path="/crying-heart" element={<ProjectSingle path={'crying-heart'} />} />
          <Route path="/ambassador-of-love" element={<ProjectSingle path={'ambassador-of-love'} />} />
          <Route path="/brother" element={<ProjectSingle path={'brother'} />} />
          <Route path="/farewell" element={<ProjectSingle path={'farewell'} />} />
          <Route path="/tide-turn" element={<ProjectSingle path={'tide-turn'} />} />
          <Route path="/imc" element={<ProjectSingle path={'imc'} />} />
          <Route path="/vr-haj" element={<ProjectSingle path={'vr-haj'} />} />
          <Route path="/vr-karbala" element={<ProjectSingle path={'vr-karbala'} />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </ScrollToTop>
      {/* <Footer /> */}
    </BrowserRouter >
  );
}

export default App;
function ScrollToTop({ children }) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <div>{children}</div>

}
