import { PUBLIC_URL } from "../../../Utils/utils";
export const tideTurn = {
    slug: 'tide-turn',
    thumbnail: PUBLIC_URL('/assets/projects/tide.jpg'),
    bg: PUBLIC_URL("/assets/projectSingles/backgrounds/tideturn.png"),
    playIcon: PUBLIC_URL("/assets/projectSingles/play-button.png"),
    video: {
        video: "https://youtu.be/QtdFLASKSWE",
        typo: PUBLIC_URL("/assets/projectSingles/typographys/tideturn.png"),
    },
    explanation: {
        image: "/assets/projectSingles/tide-turn/01.jpg",
    },
    process: {
        particles: [
            PUBLIC_URL("/assets/projectSingles/tide-turn/particle1.png"),
            PUBLIC_URL("/assets/projectSingles/tide-turn/particle2.png")
        ],
        image: "/assets/projectSingles/tide-turn/02.png",
        texts: [
            "Firstly we gathered materials for cinematics and cg arts for characters then we imported 3d models in Unreal Engine and made some changes in materials and meshs and with scene lighting we tried our best to make good looking cinematicsand show cases for characters.",
            "how ever there were technical difficulties we tried our best to have good looking outputs.",
            "we made cinematics for Manatsu, Crato, Pagos, Henry, and other characters of the game."
        ]
    },
    character: {
        texts: [
            "We used 3D models which Stanion Studio made for their characters and we developed and redesigned the texture maps and meshs and in some cases we remodeled and rigged the characters so that they would look the best in the end.",
            "The process describtion of character developemnts: Firstly 3D models were checked for any technical issues such as rigging displacements and then textures and meshs were refined and then we wrote some scenarios for different  characters to act and certain poses based on character personalities and abilities.",
        ],
        particles: [
            PUBLIC_URL("/assets/projectSingles/tide-turn/particle3.png"),
            PUBLIC_URL("/assets/projectSingles/tide-turn/particle4.png")
        ],
    },
    conceptArt: {
        sketches: [
            PUBLIC_URL("/assets/projectSingles/tide-turn/03.png"),
            PUBLIC_URL("/assets/projectSingles/tide-turn/04.png"),
            PUBLIC_URL("/assets/projectSingles/tide-turn/05.png")
        ],
        finalVersions: [
            PUBLIC_URL("/assets/projectSingles/tide-turn/final1.jpg"),
            PUBLIC_URL("/assets/projectSingles/tide-turn/final2.jpg")
        ],
    },
    cinematics: {
        text: 'We used 3D models which Stanion Studio made for their characters and we developed and redesigned the texture maps and meshs and in some cases we remodeled and rigged the characters so that they would look the best in the end.',
        videos: [
            "/https://youtu.be/BQKJC2Em28s",
            "/https://youtu.be/sey0Iqfd3ds"
        ],
    },
    galleryBgParticle: undefined,
    gallery: [
        "https://youtu.be/xNPZAdzsbpU",
        "https://youtu.be/PZm1xjZDwgA",
        "https://youtu.be/1hO4gJLmhe0",
        "https://youtu.be/zHqNXboJfSw",
        "https://youtu.be/M1RUYxefyPo",
        "https://youtu.be/cMmKSn-s_Q8",
        "https://youtu.be/EPgV-YFPXpw",
        "https://youtu.be/VGy9JddSdPk",
        "https://youtu.be/dNYTHSyCVNc"
    ],
    images: [
        PUBLIC_URL("/assets/projectSingles/tide-turn/07Copy.png"),
        PUBLIC_URL("/assets/projectSingles/tide-turn/sketch.jpg"),
        PUBLIC_URL("/assets/projectSingles/tide-turn/Henry12.jpg"),
        PUBLIC_URL("/assets/projectSingles/tide-turn/Henry13.jpg"),
        PUBLIC_URL("/assets/projectSingles/tide-turn/beatrice.jpg"),
        PUBLIC_URL("/assets/projectSingles/tide-turn/tatama.jpg"),
        PUBLIC_URL("/assets/projectSingles/tide-turn/jayden.jpg"),
    ],
};